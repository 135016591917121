import { createSlice, PayloadAction } from "@reduxjs/toolkit"; // Import necessary modules

// Define the shape of the toast state
export interface ToastState {
  message: string; // Message to be displayed in the toast
  type: "success" | "error" | ""; // Type of toast: success, error, or empty string
  duration: number; // Duration for which the toast should be displayed
}

// Define the initial state for the toast slice
const initialState: ToastState = {
  message: "",
  type: "",
  duration: 0,
};

// Create the toast slice using createSlice function
const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    // Reducer for showing a toast
    showToast(state: ToastState, action: PayloadAction<ToastState>) {
      state.message = action.payload.message; // Set message from the payload
      state.type = action.payload.type; // Set type from the payload
      state.duration = action.payload.duration; // Set duration from the payload
    },
    // Reducer for hiding the toast
    hideToast(state) {
      state.message = "";
      state.type = "";
      state.duration = 0;
    },
  },
});

// Export the action creators for showing and hiding the toast
export const { showToast, hideToast } = toastSlice.actions;

// Export the reducer function for the toast slice
export default toastSlice.reducer;
