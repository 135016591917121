import {
  ForwardedRef,
  forwardRef,
  useState,
  useImperativeHandle,
  useCallback,
  useMemo,
  useRef,
} from "react"; // React hooks for managing component state and lifecycle

import { useTranslation } from "react-i18next"; // Translation hook for multilingual support
import classNames from "classnames"; // Utility for conditionally joining classNames.

import { DEFAULT_GUEST_FIELDS } from "../../../../../utils/constants/defaultGuestFields"; // Import default guests fields with empty values

import {
  GuestFields,
  updateGuest,
} from "../../../../../store/slices/guestsSlice"; // Redux actions for managing guest data

import { validateGuestForm } from "../../../../../utils/validation"; // Utility for validation form using livr validation
import { useTypedDispatch, useTypedSelector } from "../../../../../store/store"; // Custom hooks to access typed dispatch and selectors from the store.
import titleList from "../../../../../utils/constants/titleList"; // List of provided titles for dropdown
import citizenshipList from "../../../../../utils/constants/citizenshipList"; // List of provided citizenship for dropdown
import getDaysList from "../../../../../utils/helpers/getDaysList"; // Function helper to get days
import getMonthsList from "../../../../../utils/helpers/getMonthsList"; // Function helper to get month
import getYearsList from "../../../../../utils/helpers/getYearsList"; // Function helper to get year
import genderList from "../../../../../utils/constants/genderlist"; // Function helper to get gender

import Collapsible from "../../../../shared/Collapsible"; // Import Collapsible component for creating collapsible sections in the form
import SearchInput from "../../../../shared/SearchInput"; // Import SearchInput component for rendering a search input field
import Input from "../../../../shared/Input"; // Import Input component for rendering text input fields
import CustomSelect from "../../../../shared/CustomSelect"; // Import CustomSelect component for rendering custom select dropdowns
import DatePicker from "../../../../shared/DatePicker"; // Import DatePicker component for rendering date picker input fields
import Checkbox from "../../../../shared/Checkbox"; // Import Checkbox component for rendering checkbox input fields
import TextArea from "../../../../shared/TextArea"; // Import TextArea component for rendering textarea input fields

import styles from "./index.module.scss"; // Importing SCSS module for styling.

// Init interface for props in RoomForm component
interface IRoomFormsProps {
  isLead: boolean;
  roomNumber: number;
  guestNumber: number;
}

// Init interface for validateForm function return data
interface ValidateFormOutput {
  inputs: GuestFields;
  errors: GuestFields | null;
}

/**
 * Represents a form for handling room details.
 *
 * @param {boolean} isLead - Indicates whether the guest is the lead guest.
 * @param {number} roomNumber - The room number.
 * @param {number} guestNumber - The guest number.
 * @param {object} ref - A ref object for accessing form validation methods.
 * @returns {JSX.Element} - Returns the JSX element for the room form.
 */
const RoomForm = forwardRef(function RoomForm(
  { isLead, roomNumber, guestNumber }: IRoomFormsProps,
  ref: ForwardedRef<{
    validateForm: () => ValidateFormOutput;
  }>,
) {
  const { t } = useTranslation(); // Hook for using translation
  const dispatch = useTypedDispatch(); // Hook for accessing Redux store dispatch and state

  const { guests } = useTypedSelector((state) => state.guests); // Get guests data from store

  const { date_format, iso_3_date_format } = useTypedSelector(
    (state) => state.environment,
  ); // Get dateformat from store

  // Use memoization to optimize performance by caching the results
  const guest = useMemo(
    () => guests[roomNumber]?.[guestNumber],
    [guests[roomNumber]?.[guestNumber]],
  );

  const {
    countryList,
    nationalitiesList,
    issueCountriesList,
    placeOfBirthCountriesList,
  } = useMemo(() => {
    // Init countryList and sort by alfabet
    const outputCountryList = citizenshipList
      .map((el) => ({
        label: el.country,
        value: el.iso_2,
      }))
      .sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
      );

    // Init countryList and sort by alfabet
    const outputNationalitiesList = citizenshipList
      .map((el) => ({
        label: el.country,
        value: el.iso_3,
      }))
      .sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
      );

    // Init countryList and sort by alfabet
    const outputIssueCountriesList = citizenshipList
      .map((el) => ({
        label: el.country,
        value: el.iso_3,
      }))
      .sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
      );

    const outputPlaceOfBirthCountriesList = citizenshipList
      .map((el) => ({
        label: el.country,
        value: el.iso_3,
      }))
      .sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
      );

    return {
      countryList: outputCountryList,
      nationalitiesList: outputNationalitiesList,
      issueCountriesList: outputIssueCountriesList,
      placeOfBirthCountriesList: outputPlaceOfBirthCountriesList,
    };
  }, []);

  // Ref for accessing form elements
  const formRef = useRef<HTMLDivElement>(null);

  // Init component states
  const [titleListForm, setTitleListForm] = useState(titleList);

  const [genderListForm, setGenderListForm] = useState(genderList);

  const [countryListForm, setCountryListForm] = useState(countryList);

  const [nationalitiesListForm, setNationalitiesListForm] =
    useState(nationalitiesList);

  const [issueCountriesForm, setIssueCountriesForm] =
    useState(issueCountriesList);

  const [placeOfBirthCountriesForm, setPlaceOfBirthCountriesForm] = useState(
    placeOfBirthCountriesList,
  );

  const [inputs, setInputs] = useState<GuestFields>({
    ...{ ...DEFAULT_GUEST_FIELDS, ...(guest ?? {}) },
    lead_passenger: isLead,
  });

  const [errors, setErrors] = useState<GuestFields>({
    ...DEFAULT_GUEST_FIELDS,
    lead_passenger: isLead,
  });

  const [isExpanded, setIsExpanded] = useState(inputs.isExpanded ?? isLead);
  const [isSameAddressAsLead, setIsSameAddressAsLead] = useState(false);

  // Use memoization to optimize sorting and initizialisation variables for day, month and year
  const { days, months, years } = useMemo(() => {
    const outputDays = getDaysList(inputs.yearOfBirth, inputs.monthOfBirth).map(
      (item) => ({
        value: String(item),
        label: String(item),
      }),
    );

    const outputMonths = getMonthsList().map((item) => ({
      value: item,
      label: item,
    }));

    const outputYears = getYearsList()
      .sort((a, b) => b - a)
      .map((item) => ({
        value: String(item),
        label: String(item),
      }));

    return { days: outputDays, months: outputMonths, years: outputYears };
  }, [inputs.yearOfBirth, inputs.monthOfBirth]);

  // Function to handle expanding/collapsing the form
  const handleExpand = (newIsExpanded: boolean) => setIsExpanded(newIsExpanded);

  // Handler to update lead guest's address
  const handleUpdateLeadAddress = ({
    value,
    valueKey,
  }: {
    value: string;
    valueKey: string;
  }) => {
    dispatch(
      updateGuest({
        stateroomNumber: 1,
        guestNumber: 1,
        guestFields: { ...inputs, [valueKey]: value },
      }),
    );
  };

  // Handler to update states and inputs value
  const handleInputChange = ({
    value,
    valueKey,
  }: {
    value: string;
    valueKey: string;
  }) => {
    setInputs((prev) => ({ ...prev, [valueKey]: value }));

    if (errors[valueKey as keyof GuestFields]) {
      setErrors((prev) => ({ ...prev, [valueKey]: "" }));
    }

    // Check is lead passenger form
    if (
      isLead &&
      ["address1", "address2", "city", "state", "zip_code", "country"].includes(
        valueKey,
      )
    ) {
      handleUpdateLeadAddress({
        value,
        valueKey,
      });
    }
  };

  // Handler to update states and inputs value by search inputs choosing
  const handleSearchInputChange = ({
    value,
    valueKey,
  }: {
    value: string;
    valueKey?: string;
  }) => {
    switch (valueKey) {
      case "title": {
        const filterTitleResult = titleList.filter(
          (titleLabel) =>
            titleLabel.label.toLowerCase().includes(value.toLowerCase()) ||
            titleLabel.value.toLowerCase().includes(value.toLowerCase()),
        );

        setTitleListForm(filterTitleResult);
        break;
      }

      case "gender": {
        const filterGenderResult = genderList.filter(
          (genderLabel) =>
            genderLabel.label.toLowerCase().includes(value.toLowerCase()) ||
            genderLabel.value.toLowerCase().includes(value.toLowerCase()),
        );

        setGenderListForm(filterGenderResult);
        break;
      }

      case "country": {
        const filterCountryResult = countryList.filter(
          (countryLabel) =>
            countryLabel.label.toLowerCase().includes(value.toLowerCase()) ||
            countryLabel.value.toLowerCase().includes(value.toLowerCase()),
        );

        setCountryListForm(filterCountryResult);
        break;
      }

      case "nationality": {
        const filterNationalitiesResult = nationalitiesList.filter(
          (countryLabel) =>
            countryLabel.label.toLowerCase().includes(value.toLowerCase()) ||
            countryLabel.value.toLowerCase().includes(value.toLowerCase()),
        );

        setNationalitiesListForm(filterNationalitiesResult);
        break;
      }

      case "country_of_issue": {
        const filterIssueCountries = issueCountriesList.filter(
          (countryLabel) =>
            countryLabel.label.toLowerCase().includes(value.toLowerCase()) ||
            countryLabel.value.toLowerCase().includes(value.toLowerCase()),
        );

        setIssueCountriesForm(filterIssueCountries);
        break;
      }

      case "place_of_birth": {
        const filterPlaceOfBirthCountries = placeOfBirthCountriesList.filter(
          (countryLabel) =>
            countryLabel.label.toLowerCase().includes(value.toLowerCase()) ||
            countryLabel.value.toLowerCase().includes(value.toLowerCase()),
        );

        setPlaceOfBirthCountriesForm(filterPlaceOfBirthCountries);
        break;
      }

      default: {
        break;
      }
    }
  };

  // Handler to update lead guest's same address as lead
  const onSameAddressAsLeadChange = ({ value }: { value: boolean }) => {
    setIsSameAddressAsLead(value);

    if (!isLead && !value) {
      setInputs((prev) => ({
        ...prev,
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip_code: "",
        country: "",
      }));
    }
  };

  // Function to set errors to fields if validation response error
  const updateErrors = (incomingErrors: GuestFields) => {
    setErrors((prev) => ({ ...prev, ...incomingErrors }));
  };

  // Function to validate form data
  const validateForm = useCallback(() => {
    const output: ValidateFormOutput = structuredClone({
      inputs: { ...inputs, isExpanded },
      errors: null,
    });

    const addressSource =
      !isLead && isSameAddressAsLead ? guests[1][1] : inputs;

    const country = countryList.find(
      (el) =>
        el.label === addressSource.country ||
        el.value === addressSource.country,
    );

    const nationality = nationalitiesList.find(
      (el) =>
        el.label === inputs.nationality || el.value === inputs.nationality,
    );

    const issueCountry = issueCountriesList.find(
      (el) =>
        el.label === inputs.country_of_issue ||
        el.value === inputs.country_of_issue,
    );

    const placeOfBirthCountry = placeOfBirthCountriesList.find(
      (el) =>
        el.label === inputs.place_of_birth ||
        el.value === inputs.place_of_birth,
    );

    const gender = genderList.find(
      (el) => el.label === inputs.gender || el.value === inputs.gender,
    );

    const address = {
      address1: addressSource.address1,
      address2: addressSource.address2,
      city: addressSource.city,
      state: addressSource.state,
      zip_code: addressSource.zip_code,
      country: addressSource.country,
      place_of_birth: placeOfBirthCountry?.value,
    };

    validateGuestForm({
      data: {
        ...inputs,
        ...address,
        country: country?.value,
        nationality: nationality?.value,
        country_of_issue: issueCountry?.value,
        gender: gender?.value,
      },
      onSuccess: (validData: GuestFields) => {
        output.inputs = validData;
      },
      onError: (incomingErrors: GuestFields) => {
        updateErrors(incomingErrors);
        output.errors = incomingErrors;
      },
    });

    return output;
  }, [guests, inputs, errors, isExpanded, isSameAddressAsLead]);

  // Constant for header that render as header of Collapsible of form
  const renderHeader = () => {
    return (
      <span className={styles.title}>
        {t("adult")} {guestNumber}
        {isLead && guestNumber === 1 && ` ${t("(lead guest)")}`}
      </span>
    );
  };

  // Define imperative handle to expose functions to parent component
  useImperativeHandle(
    ref,
    () => ({
      validateForm,
      expand: () => setIsExpanded(true),
      collapse: () => setIsExpanded(false),
      setErrors: (incomingErrors: GuestFields) => updateErrors(incomingErrors),
      scrollIntoView: () => formRef.current?.scrollIntoView(),
    }),
    [validateForm],
  );

  /*
    Render the form inside a Collapsible component, allowing it to expand and collapse.
    The form consists of various input fields like SearchInput, Input, CustomSelect, DatePicker, Checkbox, and TextArea.
    Each input field is associated with specific functionality such as handling user input, displaying error messages, and providing placeholders.
  */
  return (
    <Collapsible
      ref={formRef}
      expanded={isExpanded}
      onExpand={handleExpand}
      renderHeader={renderHeader}>
      <div className={styles.form}>
        {/* Input field for selecting title */}
        <div className={styles.inputs}>
          {/* SearchInput component for selecting a title */}
          <SearchInput
            className={styles.input}
            value={inputs.title}
            valueKey="title"
            displayKey="label"
            results={titleListForm}
            label={t("Title")}
            placeholder={t("Select a title")}
            errorMessage={errors.title}
            isMultiple={false}
            onChange={handleSearchInputChange}
            onChosenChange={({ value, valueKey }) =>
              handleInputChange({ value: value.value, valueKey })
            }
            isRequired
          />
        </div>

        {/* Input fields for first name and last name */}
        <div className={styles.inputs}>
          {/* Input field for first name */}
          <Input
            className={styles.input}
            value={inputs.given_name}
            valueKey="given_name"
            label={t("first name")}
            placeholder={t("Please tell us your first name")}
            onChange={handleInputChange}
            errorMessage={errors.given_name}
            name="given_name"
            isRequired
          />

          {/* Input field for last name */}
          <Input
            className={styles.input}
            value={inputs.last_name}
            valueKey="last_name"
            label={t("surname")}
            placeholder={t("Please tell us your last name")}
            onChange={handleInputChange}
            errorMessage={errors.last_name}
            name="last_name"
            isRequired
          />
        </div>

        {/* Input field for selecting gender */}
        <div className={styles.inputs}>
          {/* SearchInput component for selecting gender */}
          <SearchInput
            className={styles.input}
            value={inputs.gender}
            valueKey="gender"
            displayKey="label"
            results={genderListForm}
            label={t("Gender")}
            placeholder={t("Select a gender")}
            errorMessage={errors.gender}
            isMultiple={false}
            onChange={handleSearchInputChange}
            onChosenChange={({ value, valueKey }) => {
              handleInputChange({ value: value.label, valueKey });
            }}
            isRequired
          />
        </div>

        {/* Input fields for date of birth */}
        <div className={classNames(styles.inputs, styles.inputs_dob)}>
          {/* CustomSelect components for selecting month, day, and year of birth */}
          <CustomSelect
            className={styles.input}
            value={inputs.monthOfBirth}
            valueKey="monthOfBirth"
            label={t("month of birth")}
            placeholder={t("Month")}
            errorMessage={errors.monthOfBirth}
            items={months}
            onChange={handleInputChange}
            isRequired
          />

          <CustomSelect
            className={styles.input}
            value={inputs.dayOfBirth}
            valueKey="dayOfBirth"
            label={t("day of birth")}
            placeholder={t("Day")}
            errorMessage={errors.dayOfBirth}
            items={days}
            onChange={handleInputChange}
            isRequired
          />

          <CustomSelect
            className={styles.input}
            value={inputs.yearOfBirth}
            valueKey="yearOfBirth"
            label={t("year of birth")}
            placeholder={t("Year")}
            errorMessage={errors.yearOfBirth}
            items={years}
            onChange={handleInputChange}
            isRequired
          />
        </div>

        {/* Input field for selecting nationality */}
        <div className={styles.inputs}>
          {/* SearchInput component for selecting nationality */}
          <SearchInput
            className={styles.input}
            value={inputs.nationality}
            valueKey="nationality"
            displayKey="label"
            results={nationalitiesListForm}
            label={t("citizenship")}
            placeholder={t("Enter your Citizenship")}
            errorMessage={errors.nationality}
            isLoading={false}
            isMultiple={false}
            onChange={handleSearchInputChange}
            onChosenChange={({ value, valueKey }) =>
              handleInputChange({ value: value.label, valueKey })
            }
            isRequired
          />
        </div>

        {/* Checkbox for indicating if the address is the same as the lead guest */}
        {(!isLead || (isLead && guestNumber !== 1)) && (
          <div className={styles.inputs}>
            <Checkbox
              label={t(" The same address as lead guest")}
              value={isSameAddressAsLead}
              onChange={onSameAddressAsLeadChange}
            />
          </div>
        )}

        {/* Input fields for address information */}
        {((isLead && guestNumber === 1) || !isSameAddressAsLead) && (
          <>
            <div className={styles.inputs}>
              {/* Input field for address line 1 */}
              <Input
                className={styles.input}
                value={inputs.address1}
                valueKey="address1"
                label={t("Address Line 1")}
                placeholder={t("Your address first line")}
                onChange={handleInputChange}
                errorMessage={errors.address1}
                name="address1"
                isRequired
              />

              {/* Input field for address line 2 */}
              <Input
                className={styles.input}
                value={inputs.address2}
                valueKey="address2"
                label={t("Address Line 2")}
                placeholder={t("Your address second line")}
                onChange={handleInputChange}
                errorMessage={errors.address2}
                name="address2"
              />
            </div>

            <div className={styles.inputs}>
              {/* Input field for city */}
              <Input
                className={styles.input}
                value={inputs.city}
                valueKey="city"
                label={t("city")}
                placeholder={t("City you live in")}
                onChange={handleInputChange}
                errorMessage={errors.city}
                showError={!!errors.city}
                name="city"
                isRequired
              />

              {/* Input field for state */}
              <Input
                className={styles.input}
                value={inputs.state}
                valueKey="state"
                label={t("state")}
                placeholder={t("State you live in")}
                onChange={handleInputChange}
                errorMessage={errors.state}
                showError={!!errors.state}
                name="state"
                isRequired
              />
            </div>

            {/* Information text */}
            <p className={classNames(styles.text, styles.text_small)}>
              {t(
                "Please be advised that we will verify phone numbers and zip/postal codes against the country.",
              )}
            </p>

            <div className={styles.inputs}>
              {/* Input field for zip code */}
              <Input
                className={styles.input}
                value={inputs.zip_code}
                valueKey="zip_code"
                label={t("zip code")}
                placeholder={t("Zipcode")}
                onChange={handleInputChange}
                errorMessage={errors.zip_code}
                name="zipCode"
                isRequired
              />

              {/* SearchInput component for selecting country */}
              <SearchInput
                className={styles.input}
                value={inputs.country}
                valueKey="country"
                displayKey="label"
                results={countryListForm}
                label={t("country")}
                placeholder={t("Select a country")}
                errorMessage={errors.country}
                isLoading={false}
                isMultiple={false}
                onChange={handleSearchInputChange}
                onChosenChange={({ value, valueKey }) =>
                  handleInputChange({ value: value.label, valueKey })
                }
                isRequired
              />
            </div>
          </>
        )}

        {/* Input fields for email and phone */}
        <div className={styles.inputs}>
          {/* Input field for email */}
          <Input
            className={styles.input}
            value={inputs.email}
            valueKey="email"
            label={t("email address")}
            placeholder={t("Passenger email")}
            onChange={handleInputChange}
            errorMessage={errors.email}
            name="email"
            isRequired
          />

          {/* Input field for phone */}
          <Input
            className={styles.input}
            value={inputs.phone}
            valueKey="phone"
            label={t("phone number")}
            placeholder={t("Passenger phone number")}
            onChange={handleInputChange}
            errorMessage={errors.phone}
            name="phone"
            isRequired
          />
        </div>

        {/* Text container for passport details */}
        <div className={styles.textContainer}>
          <p className={styles.title}>{t("passport details (optional)")}</p>

          <p className={styles.text}>
            {t(
              "Passport details are optional but please make sure any details added correctly match that of the passport document.",
            )}
          </p>
        </div>

        <div className={styles.inputs}>
          {/* Input field for passport number */}
          <Input
            className={styles.input}
            value={inputs.passport_number}
            valueKey="passport_number"
            label={t("passport number")}
            placeholder={t("Passport Number")}
            onChange={handleInputChange}
            errorMessage={errors.passport_number}
            name="passportNumber"
          />
        </div>

        <div className={styles.inputs}>
          {/* DatePicker components for selecting issue and expiry dates */}
          <DatePicker
            className={styles.input}
            defaultDate={[inputs.issue_date]}
            valueKey="issue_date"
            label={t("issue date")}
            placeholder={date_format}
            errorMessage={errors.issue_date}
            displayFormat="M-d-Y"
            returnFormat={iso_3_date_format}
            mode="single"
            select="day"
            name="issueDate"
            onChange={({ value, valueKey }) => {
              handleInputChange({
                value: value[0],
                valueKey: valueKey ?? "issue_date",
              });
            }}
          />

          <DatePicker
            className={styles.input}
            defaultDate={[inputs.expiry_date]}
            valueKey="expiry_date"
            label={t("expiry date")}
            placeholder={date_format}
            errorMessage={errors.expiry_date}
            displayFormat="M-d-Y"
            returnFormat={iso_3_date_format}
            mode="single"
            select="day"
            name="expiryDate"
            onChange={({ value, valueKey }) =>
              handleInputChange({
                value: value[0],
                valueKey: valueKey ?? "expiry_date",
              })
            }
          />
        </div>

        <div className={styles.inputs}>
          <SearchInput
            className={styles.input}
            value={inputs.place_of_birth}
            valueKey="place_of_birth"
            displayKey="label"
            results={placeOfBirthCountriesForm}
            label={t("place of birth")}
            placeholder={t("Place of Birth")}
            errorMessage={errors.place_of_birth}
            isLoading={false}
            isMultiple={false}
            onChange={handleSearchInputChange}
            onChosenChange={({ value, valueKey }) =>
              handleInputChange({ value: value.label, valueKey })
            }
          />

          {/* SearchInput component for selecting country of issue */}
          <SearchInput
            className={styles.input}
            value={inputs.country_of_issue}
            valueKey="country_of_issue"
            displayKey="label"
            results={issueCountriesForm}
            label={t("country of issue")}
            placeholder={t("Country of Issue")}
            errorMessage={errors.country_of_issue}
            isLoading={false}
            isMultiple={false}
            onChange={handleSearchInputChange}
            onChosenChange={({ value, valueKey }) =>
              handleInputChange({ value: value.label, valueKey })
            }
          />
        </div>

        {/* TextArea for additional requests */}
        <TextArea
          className={styles.input}
          value={inputs.request}
          valueKey="request"
          label={t("request")}
          subtext={t(
            "Please be advised that all requests may not be able to be accommodated.",
          )}
          placeholder={t("Request")}
          onChange={handleInputChange}
          errorMessage={errors.request}
          showError={!!errors.request}
          name="request"
        />
      </div>
    </Collapsible>
  );
});

// Exporting the RoomForm component as default.
export default RoomForm;
