import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { useTypedDispatch, useTypedSelector } from "../../../store/store";
import { currencyToFormat } from "../../../utils/helpers/currency";
import { clearGuest } from "../../../store/slices/guestsSlice";
import { showModal } from "../../../store/slices/modalSlice";
import { validateRooms } from "../../../utils/validation";
import MODAL from "../../../utils/constants/modal";
import formatUrl from "../../../utils/helpers/formatUrl";

import LoadingContainer from "../../containers/LoadingContainer";
import CustomCarousel from "../../shared/Carousel";
import Button from "../../shared/Button";
import Counter from "../../shared/Counter";

import styles from "./index.module.scss";
import { insertValuesToString } from "../../../utils/helpers/insertValuesToString";
import { Market } from "../../../store/slices/searchSlice";

type InputChangeHandler = Record<string, string>;

interface IValidationInputs {
  rooms: string;
}

interface IInputs {
  rooms: { value: string; errorMessage: string };
}

function DummyCruise() {
  const { t } = useTranslation();

  return <p>{t("Cruise not found")}</p>;
}

function Cruise() {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const { pathname, search } = useLocation();

  const { cruise, isCruiseLoading } = useTypedSelector((state) => state.search);

  const { date_format, subtract_gft, gft_additional_text } = useTypedSelector(
    (state) => state.environment,
  );

  const [inputs, setInputs] = useState<IInputs>({
    rooms: { value: "1", errorMessage: "" },
  });

  // Calculate the minimum price and corresponding GFT
  const priceData = useMemo(() => {
    if (!cruise) {
      return { price: 0, gft: 0, currency: "USD" };
    }

    const prices = Object.values(cruise.markets)
      .filter(
        (market): market is Market =>
          typeof market === "object" && "price" in market && "gft" in market,
      )
      .map((market) => {
        const marketPrice = parseFloat(market.price);
        const marketGft = parseFloat(market.gft);
        const adjustedPrice = subtract_gft
          ? marketPrice - marketGft
          : marketPrice;

        return { price: adjustedPrice, gft: marketGft, currency: "USD" };
      })
      .filter((market) => !Number.isNaN(market.price) && market.price !== 0);

    if (prices.length === 0) {
      return { price: 0, gft: 0, currency: "USD" };
    }

    const minPriceData = prices.reduce((min, current) => {
      return current.price < min.price ? current : min;
    });

    return minPriceData;
  }, [cruise, subtract_gft]);

  const formattedPrice = currencyToFormat(
    priceData.price,
    cruise?.pricing?.[0]?.currency ?? priceData.currency ?? "USD",
  );

  const additionalGftText = useMemo(
    () =>
      insertValuesToString(gft_additional_text, {
        gft: currencyToFormat(
          priceData.gft,
          cruise?.pricing?.[0]?.currency ?? priceData.currency ?? "USD",
        ),
      }),
    [priceData],
  );

  const handleShowModal = () => {
    dispatch(showModal({ type: MODAL.MODAL_TYPES.ITINERARY }));
  };

  const handleInputChange = ({ value, valueKey }: InputChangeHandler) => {
    setInputs((prev) => ({
      ...prev,
      [valueKey]: { errorMessage: "", value },
    }));
  };

  const handleFormError = (errors: IValidationInputs) => {
    const updatedInputs = structuredClone(inputs);

    Object.keys(errors).forEach((errorKey) => {
      if (updatedInputs[errorKey as keyof IInputs]) {
        updatedInputs[errorKey as keyof IInputs].errorMessage =
          errors[errorKey as keyof IValidationInputs];
      }
    });

    setInputs(updatedInputs);
  };

  const handleSubmit = () => {
    validateRooms({
      data: {
        rooms: inputs.rooms.value,
      },
      onSuccess: async (validData: IValidationInputs) => {
        const url = formatUrl(
          `${pathname}/rooms/${search}&rooms=${validData.rooms}&room=1`,
        );

        navigate(url);
        window.scrollTo(0, 0);
      },
      onError: (errors: IValidationInputs) => handleFormError(errors),
    });
  };

  useEffect(() => {
    const startDate = searchParams.get("start_date");

    if (!startDate) {
      const url = formatUrl(`${pathname}/${search}`);

      navigate(url);
    }
  }, [inputs.rooms.value]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(clearGuest());
  }, []);

  if (!isCruiseLoading && !cruise) {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <DummyCruise />
        </div>
      </div>
    );
  }

  return (
    <LoadingContainer isLoading={isCruiseLoading}>
      <div className={styles.container}>
        <div className={styles.content}>
          {/* Display images, ship details, price, stateroom selection */}
          <div className={styles.imagesContainer}>
            <CustomCarousel
              items={cruise?.ship.images ?? []}
              className={styles.carousel}
            />

            <Button
              className={styles.itineraryButton}
              label={t("view itinerary")}
              variant="secondary"
              icon="plus"
              onClick={handleShowModal}
            />

            <p className={styles.name}>{cruise?.cruise.name ?? ""}</p>
          </div>

          {/* Display ship details */}
          <table className={styles.details}>
            <tbody>
              <tr>
                <td>{t("SHIP NAME")}</td>
                <td>{cruise?.ship.name}</td>
              </tr>

              <tr>
                <td>{t("DEPARTS")}</td>
                <td>{dayjs(cruise?.embark ?? "").format(date_format)}</td>
              </tr>
            </tbody>
          </table>

          {/* Display price and stateroom selection */}
          <div className={styles.price}>
            <span className={styles.price_from}>{t("From")}</span>

            <span className={styles.price_value}>{formattedPrice}&nbsp;pp</span>
          </div>

          <div className={styles.staterooms}>
            <p className={styles.staterooms_title}>
              {t("How many staterooms do you need?")}
            </p>

            <span className={styles.staterooms_subtitle}>
              {t("Some rooms sleep up to 3 guests")}
            </span>

            {/* Custom components for choosing amount of rooms */}
            <Counter
              value={inputs.rooms.value} // Current value from min to max
              valueKey="rooms"
              errorMessage={inputs.rooms.errorMessage}
              min={1} // Min return value
              max={3} // Max return value
              onChange={handleInputChange}
            />
          </div>

          {/* Continue button */}
          <Button
            label={t("continue to staterooms")}
            className={styles.submitButton}
            onClick={handleSubmit}
          />

          {gft_additional_text && (
            <span className={styles.footerNote}>{additionalGftText}</span>
          )}
        </div>
      </div>
    </LoadingContainer>
  );
}

export default Cruise;
