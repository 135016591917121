import { createSelector } from "@reduxjs/toolkit"; // Importing createSelector function from Redux Toolkit
import dayjs from "dayjs"; // Importing dayjs library for date manipulation

import { RootState, store } from "./store"; // Importing RootState type and store from the Redux store file
import { ICruise, Market } from "./slices/searchSlice"; // Importing ICruise interface from searchSlice
import { SORT_TYPE } from "./slices/searchFilterSlice"; // Importing SORT_TYPE constant from searchFilterSlice

// Selector function to get the search state from the Redux store
export const selectSearch = (state: RootState) => state.search;

// Selector function to get the search filter state from the Redux store
export const selectSearchFilter = (state: RootState) => state.searchFilter;

// Function to sort an array of cruises by name
function sortByName(array: ICruise[], ascending = true) {
  return array.slice().sort((a, b) => {
    const order = ascending ? 1 : -1;

    return order * a.ship.name.localeCompare(b.cruise.name); // Sorting cruises by ship name
  });
}

// Function to sort an array of cruises by date
function sortByDate(array: ICruise[], ascending = true) {
  return array.slice().sort((a, b) => {
    const order = ascending ? 1 : -1;

    return (
      order *
      (dayjs(a.embark).toDate().getTime() - dayjs(b.embark).toDate().getTime()) // Sorting cruises by embark date
    );
  });
}

// Function to calculate the price of a cruise based on its markets
// Function to calculate the price of a cruise based on its markets
export const getPriceByCruise = (cruise: ICruise) => {
  const prices = Object.values(cruise.markets ?? {})
    .filter(
      (market): market is Market =>
        typeof market === "object" && market !== null && "price" in market,
    )
    .map((market) =>
      parseFloat(
        `${Number(market?.price ?? "0") - Number(store.getState().environment.subtract_gft ? market?.gft ?? "0" : "0")}`,
      ),
    )
    .filter((price) => !Number.isNaN(price) && price > 0);

  return prices.length > 0 ? Math.min(...prices) : 0; // Returning the minimum price among all markets of the cruise
};

// Function to sort an array of cruises by price
function sortByPrice(array: ICruise[], ascending = true) {
  return array.slice().sort((a, b) => {
    const order = ascending ? 1 : -1;

    return order * (getPriceByCruise(a) - getPriceByCruise(b)); // Sorting cruises by price
  });
}
// Selector to filter search results based on search filters
export const selectSearchResultsByFilter = createSelector(
  [selectSearch, selectSearchFilter], // Dependencies for the selector
  (search, searchFilter) => {
    if (search && searchFilter) {
      const mutableSearch = { ...search }; // Creating a mutable copy of the search state

      if (mutableSearch.results) {
        if (searchFilter.price.length > 0) {
          mutableSearch.results = mutableSearch.results.filter(
            (result: ICruise) =>
              getPriceByCruise(result) >= searchFilter.price[0] &&
              getPriceByCruise(result) <= searchFilter.price[1], // Filtering results by price range
          );
        }

        if (searchFilter.sortType === SORT_TYPE.NANE) {
          mutableSearch.results = sortByName(
            mutableSearch.results,
            searchFilter.sortName,
          ); // Sorting results by name if sort type is name
        }

        if (searchFilter.sortType === SORT_TYPE.DATE) {
          mutableSearch.results = sortByDate(
            mutableSearch.results,
            searchFilter.sortDate,
          ); // Sorting results by date if sort type is date
        }

        if (searchFilter.sortType === SORT_TYPE.PRICE) {
          mutableSearch.results = sortByPrice(
            mutableSearch.results,
            searchFilter.sortPrice,
          ); // Sorting results by price if sort type is price
        }

        return mutableSearch; // Returning the filtered and sorted search results
      }
    }

    return search; // Returning the original search state if no filtering or sorting applied
  },
);

// Selector to calculate the minimum and maximum price among search results
export const selectSearchCalculatePrice = createSelector(
  [selectSearch],
  (search) => {
    if (search.results) {
      const minValueObject = search.results.reduce(
        (min, obj) =>
          getPriceByCruise(obj) < getPriceByCruise(min) ? obj : min, // Finding the cruise with the minimum price
        search.results[0],
      );

      const maxValueObject = search.results.reduce(
        (max, obj) =>
          getPriceByCruise(obj) > getPriceByCruise(max) ? obj : max, // Finding the cruise with the maximum price
        search.results[0],
      );

      return [
        getPriceByCruise(minValueObject), // Minimum price among search results
        getPriceByCruise(maxValueObject), // Maximum price among search results
      ];
    }

    return [0, 0]; // Default values if no search results available
  },
);
