import i18n from "../i18n";

const { t } = i18n; // Initizialisate translate function

/**
 * Object containing functions to generate error messages for required fields.
 */
export const REQUIRED_ERRORS = {
  /**
   * Generates an error message for a missing email.
   * @returns {string} Error message.
   */
  email: () => t("Email is required"),
  /**
   * Generates an error message for a missing password.
   * @returns {string} Error message.
   */
  password: () => t("Password is required"),
  /**
   * Generates an error message for a missing new password.
   * @returns {string} Error message.
   */
  newPassword: () => t("New password is required"),
  /**
   * Generates an error message for a missing start date.
   * @returns {string} Error message.
   */
  start_date: () => t("Please chose start date"),
  /**
   * Generates an error message for a missing name.
   * @returns {string} Error message.
   */
  name: () => t("Please enter name"),
  /**
   * Generates an error message for a missing address.
   * @returns {string} Error message.
   */
  address: () => t("Please enter address"),
  /**
   * Generates an error message for a missing phone number.
   * @returns {string} Error message.
   */
  telephone: () => t("Please enter phone number"),
  /**
   * Generates an error message for a missing phone number.
   * @returns {string} Error message.
   */
  phone: () => t("Please enter phone number"),
  /**
   * Generates an error message for a missing IATA number.
   * @returns {string} Error message.
   */
  iata: () => t("Please enter IATA number"),
  /**
   * Generates an error message for a missing CLIA number.
   * @returns {string} Error message.
   */
  clia: () => t("Please enter CLIA number"),
  /**
   * Generates an error message for a missing organization type.
   * @returns {string} Error message.
   */
  account_type: () => t("Please chose organization type"),
  /**
   * Generates an error message for a missing consortia.
   * @returns {string} Error message.
   */
  consortia: () => t("Please chose consortia"),
  /**
   * Generates an error message for a missing affiliation.
   * @returns {string} Error message.
   */
  affiliation: () => t("Please chose affiliation"),
  /**
   * Generates an error message for a missing file.
   * @returns {string} Error message.
   */
  file: () => t("Please attach file"),
  /**
   * Generates an error message for a missing title.
   * @returns {string} Error message.
   */
  title: () => t("Please enter your title"),
  /**
   * Generates an error message for a missing first name.
   * @returns {string} Error message.
   */
  given_name: () => t("Please enter first name"),
  /**
   * Generates an error message for a missing second name.
   * @returns {string} Error message.
   */
  last_name: () => t("Please enter second name"),
  /**
   * Generates an error message for a missing personal phone number.
   * @returns {string} Error message.
   */
  personalPhoneNumber: () => t("Please enter your phone number"),
  /**
   * Generates an error message for a missing position.
   * @returns {string} Error message.
   */
  position: () => t("Please chose position"),
  /**
   * Generates an error message for a missing password confirmation.
   * @returns {string} Error message.
   */
  confirmPassword: () => t("Password confirmation is required"),
  /**
   * Generates an error message for a missing day of birth.
   * @returns {string} Error message.
   */
  dayOfBirth: () => t("Please enter day of birth"),
  /**
   * Generates an error message for a missing month of birth.
   * @returns {string} Error message.
   */
  monthOfBirth: () => t("Please enter month of birth"),
  /**
   * Generates an error message for a missing year of birth.
   * @returns {string} Error message.
   */
  yearOfBirth: () => t("Please enter year of birth"),
  /**
   * Generates an error message for a missing nationality.
   * @returns {string} Error message.
   */
  nationality: () => t("Please enter citizenship"),
  /**
   * Generates an error message for a missing 1st line address.
   * @returns {string} Error message.
   */
  address1: () => t("Please enter 1st line address"),
  /**
   * Generates an error message for a missing 2nd line address.
   * @returns {string} Error message.
   */
  address2: () => t("Please enter 2nd line address"),
  /**
   * Generates an error message for a missing city.
   * @returns {string} Error message.
   */
  city: () => t("Please enter city"),
  /**
   * Generates an error message for a missing state.
   * @returns {string} Error message.
   */
  state: () => t("Please enter state"),
  /**
   * Generates an error message for a missing country.
   * @returns {string} Error message.
   */
  country: () => t("Please enter country"),
  /**
   * Generates an error message for a missing zip code.
   * @returns {string} Error message.
   */
  zip_code: () => t("Please enter zip code"),
  /**
   * Generates an error message for a missing gender.
   * @returns {string} Error message.
   */
  gender: () => t("Please chose your gender"),
};

/**
 * Object containing functions to generate error messages for wrong format fields.
 */
export const WRONG_FORMAT_ERRORS = {
  /**
   * Generates an error message for an invalid email format.
   * @returns {string} Error message.
   */
  email: () => t("Not valid email"),
  /**
   * Generates an error message for an invalid website URL format.
   * @returns {string} Error message.
   */
  website: () => t("Not valid URL"),
  /**
   * Generates an error message for an invalid phone format format.
   * @returns {string} Error message.
   */
  phone: () => t("Not valid phone"),
};

/**
 * Object containing functions to generate error messages for non-positive integer fields.
 */
export const NOT_POSITIVE_ERRORS = {
  /**
   * Generates an error message for a non-positive integer value for staterooms.
   * @returns {string} Error message.
   */
  staterooms: () => t("Not positive integer"),
  /**
   * Generates an error message for a non-positive integer value for rooms.
   * @returns {string} Error message.
   */
  rooms: () => t("Not positive integer"),
};

/**
 * Object containing functions to generate error messages for wrong integer range fields.
 */
export const WRONG_INTEGER_ERRORS = {
  /**
   * Generates an error message for a staterooms value out of range.
   * @returns {string} Error message.
   */
  staterooms: () => t("Value should between 1 and 3"),
  /**
   * Generates an error message for a rooms value out of range.
   * @returns {string} Error message.
   */
  rooms: () => t("Value should between 1 and 3"),
};

/**
 * Object containing functions to generate error messages for fields not being equal.
 */
export const NOT_EQUAL_ERRORS = {
  /**
   * Generates an error message for non-equal passwords.
   * @returns {string} Error message.
   */
  confirmPassword: () => t("Passwords not equal"),
};

export const TOO_SHORT_ERRORS = {
  /**
   * Generates an error message for a password being too short.
   * @returns {string} Error message.
   */
  password: () => t("Password must be at least 8 symbols"),
};
