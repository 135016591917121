// TYPES
export type ILayoutType = "auth" | "search";

export type IRouteElement = Record<
  string,
  {
    element: React.FC<React.PropsWithChildren>;
    layout: ILayoutType;
    title?: string;
    crumbParams?: string[];
  }
>;

// ROUTES
export const ROOT = "/";
export const SEARCH = `${ROOT}search`;
export const SEARCH_RESULTS = `${ROOT}search-results`;
export const CRUISE = `${SEARCH_RESULTS}/:cruiseId`;
export const ROOMS = `${CRUISE}/rooms`;
export const CABINS_SELECT = `${CRUISE}/cabin-select`;
export const PASSENGERS = `${CRUISE}/guests`;
export const PAYMENT = `${CRUISE}/payment`;
export const PAYMENT_CONFIRMATION = `${CRUISE}/payment-confirmation`;
