import { createSlice, PayloadAction } from "@reduxjs/toolkit"; // Import necessary modules

import { Grade } from "./searchSlice"; // Import Grade type from searchSlice

// Define interface for modal state
export interface ModalState {
  type: string; // Type of the modal
  data?: null | string[] | Grade; // Data associated with the modal, can be null, string array, or Grade type
}

// Define initial state for the modal
const initialState: ModalState = {
  type: "", // Initial type is empty string
  data: null, // Initial data is null
};

// Create slice for managing modal state
const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    showModal(state: ModalState, action: PayloadAction<ModalState>) {
      // Update modal type and data with the payload
      state.type = action.payload.type;
      state.data = action.payload.data;
    },
    hideModal(state) {
      // Reset modal type to empty string to hide the modal
      state.type = "";
    },
  },
});

// Export action creators and reducer
export const { showModal, hideModal } = modalSlice.actions;

export default modalSlice.reducer;
