import classNames from "classnames";

import { ILayoutType } from "../../../utils/constants/routes"; // Importing the layout type interface.
import { useTypedSelector } from "../../../store/store";

import ToastContainer from "../../containers/ToastContainer"; // Importing the ToastContainer component for displaying toasts.
import ModalContainer from "../../containers/ModalContainer"; // Importing the ModalContainer component for handling modals.
import AuthLayout from "../Auth"; // Importing the AuthLayout component for authentication-related pages.
import SearchLayout from "../Search"; // Importing the SearchLayout component for search-related pages.
import Header from "../../base/Header";
import Footer from "../../base/Footer";

import styles from "./index.module.scss"; // Importing SCSS module for styling.

// Mapping of layout types to their respective layout components.
const LAYOUTS_BY_TYPE: Record<
  ILayoutType,
  React.FC<React.PropsWithChildren>
> = {
  auth: AuthLayout,
  search: SearchLayout,
};

interface IRootLayoutProps {
  type: ILayoutType; // Props interface defining the type of layout.
}

function RootLayout({
  type,
  children,
}: React.PropsWithChildren<IRootLayoutProps>) {
  const { header_background_color, footer_background_color } = useTypedSelector(
    (state) => state.environment,
  );

  const isShowHeader = Boolean(header_background_color);
  const isShowFooter = Boolean(footer_background_color);

  const Layout = LAYOUTS_BY_TYPE[type]; // Selecting the appropriate layout component based on the provided type.

  return (
    <ToastContainer>
      {/* Wrapping the entire layout in a ToastContainer */}
      <ModalContainer>
        {/* Wrapping the entire layout in a ModalContainer */}
        <div>
          {isShowHeader && <Header />}

          {/* Main content area with styling */}
          <main
            className={classNames(styles.container, {
              [styles.container_withHeader]: isShowHeader,
            })}>
            {/* Content section with styling */}
            <section className={styles.content}>
              {/* Rendering the selected layout component with nested children */}
              <Layout>{children}</Layout>
            </section>
          </main>

          {isShowFooter && <Footer />}
        </div>
      </ModalContainer>
    </ToastContainer>
  );
}

// Exporting the RootLayout component for use in the application.
export default RootLayout;
