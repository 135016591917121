import { sessionApi } from "./apiSingleton"; // Import sessionApi from the apiSingleton module

// Define the response type for initializing API token
export interface IInitApiTokenResponse {
  token: string;
}

// Define the request parameters for initializing API token
export interface IInitApiTokenRequest {
  username: string;
  password: string;
}

// Create SessionApi by injecting an endpoint for initializing API token
export const SessionApi = sessionApi.injectEndpoints({
  endpoints: (builder) => ({
    // Define the endpoint for initializing API token
    initApiToken: builder.query<IInitApiTokenResponse, IInitApiTokenRequest>({
      query: (body) => ({
        url: "/api/token/",
        method: "POST",
        body,
      }),
    }),
  }),
});
