import { posApi } from "./apiSingleton"; // Import the posApi from the apiSingleton module

// Define the response interface for initializing POS sessions
export interface IInitPosSessionResponse {
  key: string;
}

// Define the request interface for initializing POS sessions
export interface IInitPosSessionRequest {
  agent: string;
  agency: string;
}

// Inject endpoints for POS API with a query to initialize POS sessions
export const PosAPI = posApi.injectEndpoints({
  endpoints: (builder) => ({
    initPosSession: builder.query<
      IInitPosSessionResponse, // Response data type
      IInitPosSessionRequest // Request data type
    >({
      query: (body) => ({
        url: "/sessions/", // Endpoint URL
        method: "POST", // HTTP method
        body, // Request body
      }),
    }),
  }),
});
