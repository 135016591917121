/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable func-names */
import LIVR from "livr"; // Importing the LIVR library for data validation
import extraRules from "livr-extra-rules"; // Importing additional validation rules for LIVR

// Importing error messages for validation
import {
  NOT_POSITIVE_ERRORS,
  WRONG_FORMAT_ERRORS,
  REQUIRED_ERRORS,
  NOT_EQUAL_ERRORS,
  TOO_SHORT_ERRORS,
  WRONG_INTEGER_ERRORS,
} from "./errors";

import rules from "./rules"; // Importing validation rules

// Registering extra rules with LIVR
LIVR.Validator.registerDefaultRules(extraRules);

// Enabling auto-trim for LIVR Validator
LIVR.Validator.defaultAutoTrim(true);

/**
 * Validates data against a given set of rules.
 *
 * @param {Object} rule - Validation rule.
 * @param {Object} data - Data to be validated.
 * @param {Function} onSuccess - Function to be called if validation succeeds.
 * @param {Function} onError - Function to be called if validation fails.
 */
function validate({ rule, data, onSuccess, onError }) {
  const validator = new LIVR.Validator(rule); // Creating a new LIVR validator instance

  // Register custom rule for phone number validation
  validator.registerRules({
    phone: () => {
      const phoneRegex = /^[0-9+ ]+$/;

      return function (value) {
        if (typeof value !== "string" || !phoneRegex.test(value)) {
          return "PHONE_FORMAT_ERROR";
        }
      };
    },
  });

  const validData = validator.validate(data); // Validating the data using the provided rule

  if (validData && onSuccess) {
    // Invoke success callback if provided
    if (onSuccess) {
      onSuccess(validData);
    }
  } else {
    const decodedErrors = decodeErrorObject(validator.getErrors());

    if (onError) {
      onError(decodedErrors); // Invoke error callback with decoded errors
    }
  }
}

/**
 * Validates search data.
 * @param {Object} args - Arguments for validation.
 */
export function validateSearch(args) {
  return validate({ rule: rules.search, ...args }); // Delegates validation to the generic validate function
}

/**
 * Validates room data.
 * @param {Object} args - Arguments for validation.
 */
export function validateRooms(args) {
  return validate({ rule: rules.rooms, ...args }); // Delegates validation to the generic validate function
}

/**
 * Validates guest form data.
 * @param {Object} args - Arguments for validation.
 */
export function validateGuestForm(args) {
  return validate({ rule: rules.guestForm, ...args }); // Delegates validation to the generic validate function
}

/**
 * Decodes error object into human-readable messages.
 * @param {Object} errors - Error object.
 * @returns {Object} Decoded error object.
 */
function decodeErrorObject(errors) {
  const decodedErrors = { ...errors };

  // Iterate over each field in the error object
  for (const field in decodedErrors) {
    if (Object.prototype.hasOwnProperty.call(decodedErrors, field)) {
      const errorField = field.replace("data/", ""); // Remove "data/" prefix from the field name

      // Decode the error code for the field
      decodedErrors[errorField] = decodeErrorCode(
        decodedErrors[field],
        errorField,
      );
    }
  }

  return decodedErrors;
}

/**
 * Decodes error codes into human-readable messages.
 * @param {string} code - Error code.
 * @param {string} field - Field name.
 * @returns {string} Error message.
 */
export function decodeErrorCode(code, field = "") {
  switch (code) {
    case "REQUIRED": {
      // Get error message for required field
      const errorMessage =
        field && REQUIRED_ERRORS[field] && REQUIRED_ERRORS[field]();

      return errorMessage || "Required";
    }

    case "WRONG_DATE":
    case "WRONG_EMAIL":
    case "WRONG_URL": {
      // Get error message for wrong format
      const errorMessage =
        field && WRONG_FORMAT_ERRORS[field] && WRONG_FORMAT_ERRORS[field]();

      return errorMessage || "Wrong format";
    }

    case "NOT_POSITIVE_INTEGER": {
      // Get error message for not positive integer
      const errorMessage =
        field && NOT_POSITIVE_ERRORS[field] && NOT_POSITIVE_ERRORS[field]();

      return errorMessage || "Not positive";
    }

    case "FIELDS_NOT_EQUAL": {
      // Get error message for fields not equal
      const errorMessage =
        field && NOT_EQUAL_ERRORS[field] && NOT_EQUAL_ERRORS[field]();

      return errorMessage || "Not equal";
    }

    case "TOO_SHORT": {
      // Get error message for too short value
      const errorMessage =
        field && TOO_SHORT_ERRORS[field] && TOO_SHORT_ERRORS[field]();

      return errorMessage || "Too short";
    }

    case "TOO_LOW":
    case "TOO_HIGH": {
      // Get error message for wrong integer value
      const errorMessage =
        field && WRONG_INTEGER_ERRORS[field] && WRONG_INTEGER_ERRORS[field]();

      return errorMessage || "Wrong value";
    }

    case "PHONE_FORMAT_ERROR": {
      // Get error message for wrong integer value
      const errorMessage =
        field && WRONG_FORMAT_ERRORS[field] && WRONG_FORMAT_ERRORS[field]();

      return errorMessage || "Wrong value";
    }

    default: {
      // Return the error code if no specific message found
      return code;
    }
  }
}
