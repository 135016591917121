// Import necessary dependencies and components.
import { useDispatch } from "react-redux"; // Hook for accessing Redux dispatch function.
import { useEffect, useState } from "react"; // React hooks for managing component state and side effects.

import { hideToast } from "../../../store/slices/toastSlice"; // Action for hiding toast in the Redux store.
import { useTypedSelector } from "../../../store/store"; // Custom hook for accessing typed selectors from the Redux store.

import Toast from "./molecules/Toast"; // Component for displaying toast.

import styles from "./index.module.scss"; // Styles specific to ToastContainer component.

// Interface defining the structure of a toast.
interface IToast {
  isVisible: boolean; // Flag indicating whether the toast is visible.
  message: string; // Content of the toast message.
  type: string; // Type of the toast (e.g., "success", "error").
  duration: number; // Duration for which the toast is displayed.
}

// Duration for switching toasts.
const SWITCH_TOAST_DURATION = 500;

// Default state for an active toast.
const DEFAULT_ACTIVE_TOAST = {
  isVisible: false,
  message: "",
  type: "",
  duration: 0,
};

// ToastContainer component responsible for managing and rendering toast notifications.
function ToastContainer({ children }: React.PropsWithChildren) {
  const dispatch = useDispatch(); // Access the Redux dispatch function.

  const toastState = useTypedSelector((state) => state.toast); // Retrieve toast state from the Redux store.

  // State variables to manage the queue of toasts and the currently active toast.
  const [toastQueue, setToastQueue] = useState([DEFAULT_ACTIVE_TOAST]);
  const [activeToast, setActiveToast] = useState<IToast>(DEFAULT_ACTIVE_TOAST);

  // Function to hide the active toast.
  const hideActiveToast = () => {
    setActiveToast((prevToast) => ({ ...prevToast, isVisible: false }));

    setTimeout(() => {
      setActiveToast(DEFAULT_ACTIVE_TOAST);
    }, SWITCH_TOAST_DURATION);
  };

  // Function to show the next toast in the queue.
  const showNextToast = () => {
    if (toastQueue.length > 0) {
      const nextToast = toastQueue[0];

      setActiveToast(nextToast);

      setTimeout(() => {
        setToastQueue((prevQueue) => prevQueue.slice(1));

        hideActiveToast();
        dispatch(hideToast());
      }, nextToast.duration);
    }
  };

  // Effect to add a new toast to the queue when the toast state changes.
  useEffect(() => {
    if (toastState.message) {
      const newToast = { ...toastState, isVisible: true };

      setToastQueue([...toastQueue, newToast]);
    }
  }, [toastState.message]);

  // Effect to show the next toast when the toast queue or active toast changes.
  useEffect(() => {
    if (!activeToast.message) {
      showNextToast();
    }
  }, [toastQueue, activeToast]);

  // Render the ToastContainer component.
  return (
    <div className={styles.container}>
      {children}

      <Toast
        isVisible={activeToast.isVisible}
        message={activeToast.message}
        type={activeToast.type}
      />
    </div>
  );
}

// Export the ToastContainer component for use in other parts of the application.
export default ToastContainer;
