import { agencyApi } from "./apiSingleton"; // Import agencyApi from the apiSingleton module

// Define types for API responses and request bodies
export type IInitConsortiaResponse = Array<{
  code: string;
  commission: null;
  id: number;
  name: string;
}>;

export type IInitMarketsResponse = Array<{
  code: string;
  id: number;
  name: string;
}>;

export interface ICreateAgencyRequest {
  sales_area: string;
  api_accounts: string;
  name: string;
  commission_level: string;
  credit_limit: string;
  code: string;
  dba: string;
  address: { name: string; value: string };
  telephone: string;
  website: string;
  iata: string;
  clia: string;
  account_type: string;
  consortia: string;
  market: string;
  file?: File;
  title: string;
  firstName: string;
  surname: string;
  personalPhoneNumber: string;
  position: string;
  personalAddress: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export interface ICreateAgentRequest {
  sales_area: string;
  name: string;
  firstName: string;
  surname: string;
  address: { name: string; value: string };
  telephone: string;
  code: string;
  agency: string;
  position: string;
  iata: string;
  clia: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export type ISearchAgencyResponse = Array<Record<string, string>>;

// Define API endpoints using the builder's query method
export const AgencyAPI = agencyApi.injectEndpoints({
  endpoints: (builder) => ({
    initConsortia: builder.query<IInitConsortiaResponse, null>({
      query: () => ({
        url: "/cnf/consortia/",
        method: "GET",
      }),
    }),
    initMarkets: builder.query<IInitMarketsResponse, null>({
      query: () => ({
        url: "/cnf/market/",
        method: "GET",
      }),
    }),
    createAgency: builder.query<null, ICreateAgencyRequest>({
      query: (body) => ({
        url: "/ops/agency/",
        method: "POST",
        body,
      }),
    }),
    createAgent: builder.query<null, ICreateAgentRequest>({
      query: (body) => ({
        url: "/ops/agent/",
        method: "POST",
        body,
      }),
    }),
    searchAgencies: builder.query<ISearchAgencyResponse, null>({
      query: () => ({
        url: "/ops/agency/",
        method: "GET",
      }),
    }),
  }),
});

// Destructure useLazySearchAgenciesQuery from AgencyAPI
export const { useLazySearchAgenciesQuery } = AgencyAPI;
