import { createSlice, PayloadAction } from "@reduxjs/toolkit"; // Import necessary modules

// Define the shape of the Search Filter state
export interface SearchFilterState {
  price: number[];
  sortName: boolean;
  sortDate: boolean;
  sortPrice: boolean;
  sortType: string;
}

// Define constant values for sort types
export const SORT_TYPE = {
  NANE: "name",
  DATE: "date",
  PRICE: "price",
};

// Define the initial state for the Search Filter slice
const initialState: SearchFilterState = {
  price: [],
  sortType: SORT_TYPE.DATE,
  sortName: true,
  sortDate: true,
  sortPrice: true,
};

// Create Search Filter slice using createSlice function
const searchFilterSlice = createSlice({
  name: "searchFilter",
  initialState,
  reducers: {
    filterRangePrice(
      state: SearchFilterState,
      action: PayloadAction<number[]>,
    ) {
      state.price = action.payload; // Update price range filter with the payload
    },
    sortName(state: SearchFilterState, action: PayloadAction<boolean>) {
      searchFilterSlice.caseReducers.clearFilterSort(state); // Clear other sorting flags

      state.sortName =
        state.sortType !== SORT_TYPE.NANE ? true : action.payload; // Toggle sort by name flag
      state.sortType = SORT_TYPE.NANE; // Update sort type to name
    },
    sortDate(state: SearchFilterState, action: PayloadAction<boolean>) {
      searchFilterSlice.caseReducers.clearFilterSort(state); // Clear other sorting flags

      state.sortDate =
        state.sortType !== SORT_TYPE.DATE ? true : action.payload; // Toggle sort by date flag
      state.sortType = SORT_TYPE.DATE; // Update sort type to date
    },
    sortPrice(state: SearchFilterState, action: PayloadAction<boolean>) {
      searchFilterSlice.caseReducers.clearFilterSort(state); // Clear other sorting flags

      state.sortPrice =
        state.sortType !== SORT_TYPE.PRICE ? true : action.payload;
      state.sortType = SORT_TYPE.PRICE; // Update sort type to price
    },
    clearFilterSort(state: SearchFilterState) {
      // Reset all sorting flags
      state.sortName = true;
      state.sortDate = true;
      state.sortPrice = true;
    },
  },
});

// Export actions from Search Filter slice
export const { filterRangePrice, sortName, sortDate, sortPrice } =
  searchFilterSlice.actions;

// Export the reducer function for Search Filter slice
export default searchFilterSlice.reducer;
