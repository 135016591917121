import { useTypedSelector } from "../../store/store"; // Importing the custom hook to access typed selectors from the Redux store.

import LoadingContainer from "../containers/LoadingContainer"; // Importing the LoadingContainer component responsible for displaying loading indicators.
import SessionGuard from "./SessionGuard"; // Importing the SessionGuard component used for guarding routes based on session authentication.

// RootGuard component responsible for guarding the root of the application.
function RootGuard({ children }: React.PropsWithChildren) {
  // Using the useTypedSelector hook to access the Redux store state and check if the application is initialized.
  const isAppInitialized = useTypedSelector(
    (state) => state.session.isInitialized && state.environment.isInitialized,
  );

  return (
    // Wrapping the content with SessionGuard to ensure session authentication.
    <SessionGuard>
      {/* 
        Using LoadingContainer to conditionally display loading indicators 
        based on the initialization state of the application.
      */}
      <LoadingContainer isLoading={!isAppInitialized}>
        {/* Rendering the children components within the LoadingContainer. */}
        {children}
      </LoadingContainer>
    </SessionGuard>
  );
}

// Exporting the RootGuard component for use in other parts of the application.
export default RootGuard;
