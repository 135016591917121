// Import necessary modules and components
import { MutableRefObject, useEffect, useRef } from "react"; // React hooks for managing component state and lifecycle
import { useTranslation } from "react-i18next"; // Translation hook for multilingual support
import { nanoid } from "@reduxjs/toolkit"; // Unique ID generation

import { useTypedDispatch, useTypedSelector } from "../../../store/store"; // Redux hooks for typed dispatch and selector
import { showModal } from "../../../store/slices/modalSlice"; // Redux action to show a modal
import { GuestFields, updateGuest } from "../../../store/slices/guestsSlice"; // Redux actions for managing guest data
import { setPricing } from "../../../store/slices/pricingSlice"; // Redux action for setting pricing data
import MODAL from "../../../utils/constants/modal"; // Constants for modal types

import LoadingContainer from "../../containers/LoadingContainer"; // Container component for loading state
import Button from "../../shared/Button"; // Custom Button component
import BookingDetails from "../../base/BookingDetails"; // Component for displaying booking details
import RoomForms from "./molecules/RoomForms"; // Component for room forms
import SubmitButton from "./molecules/SubmitButton"; // Component for submit button

import styles from "./index.module.scss"; // Importing SCSS module for styling.

// Define types for form validation output and submit button payload
type ValidateFormsOutput = Record<
  number,
  Record<
    number,
    {
      inputs: GuestFields;
      errors: Partial<GuestFields>;
      expand: () => void;
      setErrors: (errors: Partial<Record<keyof GuestFields, string>>) => void;
      scrollIntoView: () => void;
    }
  >
>;

// Init type for guests as argument for submit function
type SubmitButtonPayload = Record<
  number,
  Record<
    number,
    {
      inputs: GuestFields;
    }
  >
>;

// Component for managing passengers information
function Passengers() {
  const { t } = useTranslation(); // Translation function
  const dispatch = useTypedDispatch(); // Redux dispatch function

  // Redux state selectors
  const { isCruiseLoading } = useTypedSelector((state) => state.search);
  const { rooms } = useTypedSelector((state) => state.rooms);
  const { check_email_uniqueness } = useTypedSelector(
    (state) => state.environment,
  );

  // Refs for managing form validation and submit button
  const submitButtonRef: MutableRefObject<{
    submit: (guests: SubmitButtonPayload) => void;
  }> = useRef({
    submit: () => null,
  });

  // Refs for managing room forms
  const roomsFormsRefs: Record<
    number,
    MutableRefObject<{
      validateForms: () => ValidateFormsOutput;
    }>
  > = { 1: useRef({ validateForms: () => ({}) }) };

  // Function to show itinerary modal
  const handleShowItineraryModal = () => {
    dispatch(showModal({ type: MODAL.MODAL_TYPES.ITINERARY }));
  };

  // Function to flatten and validate all room forms
  const flattenForms = () => {
    let validationResult: ValidateFormsOutput = {};

    Object.entries(roomsFormsRefs).forEach(([, room]) => {
      const roomFormsValidationResult: ValidateFormsOutput =
        room.current.validateForms();

      validationResult = {
        ...validationResult,
        ...(roomFormsValidationResult ?? {}),
      };
    });

    if (check_email_uniqueness) {
      const emails: string[] = [];

      Object.entries(validationResult).forEach(([roomKey, room]) => {
        const roomNumber = Number(roomKey);

        Object.entries(room).forEach(
          ([guestKey, { inputs, errors, setErrors }]) => {
            const guestIndex = Number(guestKey);
            const isEmailExistsAndValid = !errors?.email && inputs.email;
            const isEmailUnique = !emails.includes(inputs.email);

            if (isEmailExistsAndValid && !isEmailUnique) {
              setErrors({ email: t("Email should be unique") });

              if (!errors) {
                validationResult[roomNumber][guestIndex].errors = {};
              }

              validationResult[roomNumber][guestIndex].errors.email = t(
                "Email should be unique",
              );
            }

            emails.push(inputs.email);
          },
        );
      });
    }

    return validationResult;
  };

  // Function to handle form validation and submission
  const handleValidate = () => {
    const validationResult = flattenForms();

    let isFormsValid = true;

    Object.entries(validationResult).forEach(([roomKey, room]) => {
      const roomNumber = Number(roomKey);

      Object.entries(room).forEach(([formKey, form]) => {
        const isFormValid = !form.errors;

        if (!isFormValid && isFormsValid) {
          requestAnimationFrame(() => {
            form.expand();

            setTimeout(() => {
              form.scrollIntoView();
            }, 500);
          });

          isFormsValid = false;
        }

        if (isFormValid) {
          dispatch(
            updateGuest({
              stateroomNumber: roomNumber,
              guestNumber: Number(formKey),
              guestFields: form.inputs,
            }),
          );
        }
      });
    });

    if (isFormsValid) {
      submitButtonRef.current.submit(validationResult);
    }
  };

  // Effect to set pricing when rooms change
  useEffect(() => {
    dispatch(setPricing(rooms));
  }, [rooms]);

  // Effect to scroll to top on component mount
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // Render the component structure
  return (
    <LoadingContainer isLoading={isCruiseLoading || !rooms}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.left}>
            <h1 className={styles.title}>{t("who’s travelling")}</h1>

            {/* Render room forms */}
            <div className={styles.passengers}>
              {Object.entries(rooms ?? {}).map(
                ([stateroomKey, stateroom], stateroomIndex) => {
                  const ref = useRef({ validateForms: () => ({}) });
                  const description =
                    stateroom?.grade?.descriptions?.[0]?.description;

                  roomsFormsRefs[+stateroomKey] = ref;

                  return (
                    <div key={nanoid()} className={styles.passenger}>
                      {/* Render info about room */}
                      <div className={styles.stateroomInfo}>
                        <p className={styles.subtitle}>
                          stateroom {stateroomIndex + 1}
                        </p>

                        <p className={styles.name}>
                          {stateroom?.grade?.name}
                          <span>{description ? ` - ${description}` : ""}</span>
                        </p>

                        <p className={styles.name}>
                          {stateroom?.guestsNumber} guests
                        </p>
                        <p className={styles.name}>
                          stateroom {stateroom?.cabin?.number}
                        </p>
                      </div>

                      {/* Render all guest forms for each room */}
                      <RoomForms
                        ref={ref}
                        isIncludesLead={stateroomIndex === 0}
                        roomNumber={+stateroomKey}
                        guestsAmount={stateroom?.guestsNumber ?? 1}
                      />
                    </div>
                  );
                },
              )}
            </div>

            {/* Render submit button */}
            <SubmitButton ref={submitButtonRef} onClick={handleValidate} />
          </div>

          {/* Right side content */}
          <div className={styles.right}>
            {/* Button to show itinerary modal */}
            <Button
              label={t("view itinerary")}
              variant="secondary"
              icon="plus"
              onClick={handleShowItineraryModal}
            />

            {/* Display booking details */}
            <BookingDetails />
          </div>
        </div>
      </div>
    </LoadingContainer>
  );
}

// Exporting the Passengers component as default.
export default Passengers;
