import { useMemo } from "react"; // Import React hook
import { t } from "i18next"; // Import translation function from i18next
import classNames from "classnames"; // Import classNames library for conditional class application
import { nanoid } from "@reduxjs/toolkit"; // Import unique identifier generator from Redux Toolkit

import { currencyToFormat } from "../../../../../utils/helpers/currency"; // Import utility function for formatting currency
import { useTypedSelector } from "../../../../../store/store"; // Import Typed Selector hook from Redux store

import styles from "./index.module.scss"; // Import component styles

/**
 * StateroomPriceBreakdown component displays a breakdown of pricing for a given stateroom.
 *
 * @param {number} roomNumber - The number of the stateroom.
 * @returns {JSX.Element} StateroomPriceBreakdown component JSX elements.
 */
function StateroomPriceBreakdown({ roomNumber }: { roomNumber: number }) {
  // Retrieve rooms from Redux store using Typed Selector hook
  const { rooms } = useTypedSelector((state) => state.rooms);
  const { cruise } = useTypedSelector((state) => state.search);

  // Memoize room data to avoid unnecessary re-renders
  const room = useMemo(() => {
    if (rooms && roomNumber) {
      return rooms[roomNumber];
    }

    return undefined;
  }, [rooms, roomNumber]);

  // Memoize pricing details for the room
  const pricing = useMemo(() => {
    // Check if room data is available
    if (!room?.guestsNumber || !room?.grade) {
      // Return default pricing if room data is incomplete
      return { prices: [], fare: 0, tax: 0, total: 0 };
    }

    const { grade, guestsNumber, fare } = room;

    // Calculate tax for the room
    const tax = fare?.prices.find((item) => item.grade === grade.code)?.gft
      ? Number(fare?.prices.find((item) => item.grade === grade.code)?.gft)
      : 0;

    // Define fare prices based on guest number
    const pricesByGuestNumber: Record<number, number> = {
      1: grade.single,
      2: grade.double,
      3: grade.triple,
      4: grade.quadruple,
    };

    const prices = [];

    // Calculate pricing details for each guest
    const roomPricing = {
      fare: pricesByGuestNumber[guestsNumber] - tax,
      total: pricesByGuestNumber[guestsNumber],
      tax,
    };

    // Iterate over the number of guests in the room
    for (let i = 0; i < room.guestsNumber; i += 1) {
      // Push pricing details for each guest to the prices array
      prices.push({
        guestNumber: i + 1,
        tax: roomPricing.tax,
        fare: roomPricing.fare,
        total: roomPricing.total,
      });
    }

    // Return pricing details for the room
    return { prices, ...roomPricing };
  }, [room]);

  // Render component JSX
  return (
    <div className={styles.body}>
      {/* Iterate over each guest in the room */}
      <div key={nanoid()} className={styles.room}>
        <table>
          <tbody>
            <tr>
              {/* Display number of guests */}
              <td>
                <p className={styles.tableHeading}>{t("guests")}</p>
              </td>

              <td>
                <p className={styles.tableValue}>
                  {`${room?.guestsNumber ?? "N/A"} ${t("adult(s)")}`}
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <hr className={styles.line} />

        <div className={styles.guests}>
          {/* Display pricing details for each guest */}
          {pricing.prices.map((guest) => {
            const { guestNumber, fare, tax, total } = guest;

            return (
              <div key={guestNumber} className={styles.guest}>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        {/* Display fare for each guest */}
                        <p
                          className={classNames(
                            styles.tableHeading,
                            styles.guestHeading,
                          )}>
                          {t("fare")}
                        </p>
                      </td>

                      <td>
                        <p
                          className={classNames(
                            styles.tableValue,
                            styles.guestValue,
                          )}>
                          {/* Format fare currency */}
                          {currencyToFormat(
                            fare ?? 0,
                            cruise?.pricing?.[0]?.currency ?? "USD",
                          )}
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        {/* Display tax for each guest */}
                        <p
                          className={classNames(
                            styles.tableHeading,
                            styles.guestHeading,
                          )}>
                          {t("tax")}
                        </p>
                      </td>

                      <td>
                        <p
                          className={classNames(
                            styles.tableValue,
                            styles.guestValue,
                          )}>
                          {/* Format tax currency */}
                          {currencyToFormat(
                            tax,
                            cruise?.pricing?.[0]?.currency ?? "USD",
                          )}
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p
                          className={
                            styles.tableHeading
                          }>{`${t("guest")} ${guestNumber} ${t("subtotal")}`}</p>
                      </td>

                      <td>
                        {/* Display total cost for each guest */}
                        <p className={styles.tableValue}>
                          {/* Format total currency */}
                          {currencyToFormat(
                            total,
                            cruise?.pricing?.[0]?.currency ?? "USD",
                          )}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <hr className={styles.line} />
              </div>
            );
          })}
        </div>

        <table>
          <tbody>
            <tr>
              <td>
                {/* Display total fare for the stateroom */}
                <p
                  className={
                    styles.tableHeading
                  }>{`${t("stateroom")} ${roomNumber} ${t("fare")}:`}</p>
              </td>

              <td>
                <p className={styles.tableValue}>
                  {/* Format total fare currency */}
                  {currencyToFormat(
                    `${(pricing.total ?? 0) * (room?.guestsNumber ?? 1)}`,
                    cruise?.pricing?.[0]?.currency ?? "USD",
                  )}
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <hr className={styles.line} />
      </div>
    </div>
  );
}

// Export the component
export default StateroomPriceBreakdown;
