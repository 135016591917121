import { searchApi } from "./apiSingleton"; // Import the searchApi from the apiSingleton file
import { store } from "../store"; // Import the Redux store

// Define interfaces for various responses and requests related to cabin selection
export interface Cabin {
  location: string;
  maximum_occupancy: number;
  minimum_occupancy: number;
  number: string;
  position: string;
  size: string;
}

type ICabinsResponse = Cabin[];

interface ICabinsRequest {
  cruiseId: string;
  rateCode: string;
  gradeCode: string;
  guestCount: string;
  guestsDobQs: string;
  source: string | any;
}

interface IHoldCabinResponse {
  cabin_number: string;
  held_at: string;
  held_until: string;
}

interface IHoldCabinRequest {
  cabin_number: string;
  grade_code: string;
  rate_code: string;
  sailing_code: string;
  agency: string;
}

interface ICreateReservationRequest {
  rate_code: string;
  cruiseId: string;
  source: string;
  cabins: Array<{
    cabin_number: string;
    dining_seating: string;
    dining_table: string;
    grade_code: string;
    guests: Array<{
      address: string[];
      city: string;
      country: string;
      email: string;
      given_name: string;
      language: string;
      lastname: string;
      last_name: string;
      lead_passenger: boolean;
      nationality: string;
      phone: string;
      state: string;
      title: string;
      gender: string;
      zip_code: string;
    }>;
  }>;
}

interface IGetPricingResponse {
  total_price: string;
  payment_schedule: Array<{ amount: string; currency: string; date: string }>;
  guests: Array<{
    discount: string;
    gft: string;
    guest: number;
    nccf: string;
    total_fare: string;
    total_price: string;
  }>;
}

interface IGetPricingRequest {
  sailing_code: string;
  source: string;
  rate_code: string;
  grade_code: string;
  cabin_number: string;
  guest_count: string;
  guests_dobs: string;
}

// Define the CabinSelectAPI with multiple endpoints for cabin-related queries
export const CabinSelectAPI = searchApi.injectEndpoints({
  endpoints: (builder) => ({
    initCabins: builder.query<ICabinsResponse, ICabinsRequest>({
      query: ({
        cruiseId,
        rateCode,
        gradeCode,
        guestCount,
        guestsDobQs,
        source,
      }) => {
        // Extract sessionKey and api_language from store state
        const { sessionKey } = store.getState().session;
        const { api_language } = store.getState().environment;

        // Construct the URL with necessary parameters
        return {
          url: `/cabins/${cruiseId}?session=${
            sessionKey ?? ""
          }&language=${api_language ?? "en-us"}&source=${source}&rate_code=${rateCode}&grade_code=${gradeCode}&guest_count=${guestCount}&${guestsDobQs}`,
        };
      },
    }),
    holdCabin: builder.query<IHoldCabinResponse, IHoldCabinRequest>({
      query: ({
        cabin_number,
        grade_code,
        rate_code,
        sailing_code,
        agency,
      }) => {
        // Extract sessionKey from store state
        const { sessionKey: session } = store.getState().session;

        // Construct the URL with necessary parameters
        return {
          url: `/holdcabin/${sailing_code}/${agency}/`,
          method: "POST",
          body: {
            cabin_number,
            grade_code,
            rate_code,
            sailing_code,
            session,
          },
        };
      },
    }),
    createReservation: builder.query<
      { pnr: string },
      ICreateReservationRequest
    >({
      query: ({ rate_code, cruiseId, source, cabins }) => {
        // Extract sessionKey, api_agency, and api_language from store state
        const { sessionKey: session } = store.getState().session;

        const { api_agency: agency, api_language: language } =
          store.getState().environment;

        // Construct the URL with necessary parameters
        return {
          url: `/reservation/${cruiseId}/${source}/`,
          method: "POST",
          body: {
            agency,
            cabins,
            language,
            promotions: [],
            rate_code,
            session,
          },
        };
      },
    }),
    getPricing: builder.query<IGetPricingResponse, IGetPricingRequest>({
      query: ({
        sailing_code,
        source,
        rate_code,
        grade_code,
        cabin_number,
        guest_count,
        guests_dobs,
      }) => {
        // Extract sessionKey and api_language from store state
        const { sessionKey } = store.getState().session;
        const { api_language } = store.getState().environment;

        // Construct the URL with necessary parameters
        return {
          url: `/pricing/${sailing_code}/?sailing_code=${sailing_code}&session=${sessionKey}&source=${source}&language=${api_language}&rate_code=${rate_code}&grade_code=${grade_code}&cabin_number=${cabin_number}&guest_count=${guest_count}&${guests_dobs}`,
          method: "GET",
        };
      },
    }),
  }),
});

// Extract the generated hooks from CabinSelectAPI
export const {
  useLazyInitCabinsQuery,
  useLazyHoldCabinQuery,
  useLazyCreateReservationQuery,
  useLazyGetPricingQuery,
} = CabinSelectAPI;
