import { combineReducers, configureStore, Reducer } from "@reduxjs/toolkit"; // Importing necessary functions and types from Redux Toolkit
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"; // Importing hooks from React Redux
import storageSession from "redux-persist/lib/storage/session"; // Importing storage for session persistence
import { persistReducer, persistStore } from "redux-persist"; // Importing functions for state persistence

import api from "./services/apiSingleton"; // Importing API singleton instance
import session from "./slices/sessionSlice"; // Importing session slice reducer
import toast from "./slices/toastSlice"; // Importing toast slice reducer
import modal from "./slices/modalSlice"; // Importing modal slice reducer
import search from "./slices/searchSlice"; // Importing search slice reducer
import rooms from "./slices/roomsSlice"; // Importing rooms slice reducer
import agency from "./slices/agencySlice"; // Importing agency slice reducer
import reservation from "./slices/reservationSlice"; // Importing reservation slice reducer
import environment from "./slices/environmentSlice"; // Importing environment slice reducer
import searchFilter from "./slices/searchFilterSlice"; // Importing search filter slice reducer
import sessionMiddleware from "./middlewares/sessionMiddleware"; // Importing session middleware
import guests from "./slices/guestsSlice"; // Importing guests slice reducer
import pricing from "./slices/pricingSlice"; // Importing pricing slice reducer
import locales from "./slices/localesSlice"; // Importing locales slice reducer

// Configuration for state persistence
const persistConfig = {
  key: "root", // Key for the persisted state
  storage: storageSession, // Storage mechanism for session persistence
  whitelist: ["rooms", "guests"], // List of slices to persist
};

// Object containing all reducers
const reducers = {
  [api.session.reducerPath]: api.session.reducer, // API session slice reducer
  [api.pos.reducerPath]: api.pos.reducer, // API POS slice reducer
  [api.search.reducerPath]: api.search.reducer, // API search slice reducer
  [api.agency.reducerPath]: api.agency.reducer, // API agency slice reducer
  [api.environment.reducerPath]: api.environment.reducer, // API environment slice reducer
  [api.payment.reducerPath]: api.payment.reducer, // API payment slice reducer
  [api.reservation.reducerPath]: api.reservation.reducer, // API reservation slice reducer
  [api.locales.reducerPath]: api.locales.reducer, // API locales slice reducer
  session, // Session slice reducer
  toast, // Toast slice reducer
  modal, // Modal slice reducer
  search, // Search slice reducer
  rooms, // Rooms slice reducer
  agency, // Agency slice reducer
  reservation, // Reservation slice reducer
  environment, // Environment slice reducer
  searchFilter, // Search filter slice reducer
  guests, // Guests slice reducer
  pricing, // Pricing slice reducer
  locales, // Locales slice reducer
};

// Combined reducer function
const combinedReducer = combineReducers<typeof reducers>(reducers);

// Root reducer function
export const rootReducer: Reducer<RootState> = (state, action) => {
  return combinedReducer(state, action); // Combining all reducers
};

// Persisted reducer using persistReducer function
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Redux store configuration
export const store = configureStore({
  reducer: persistedReducer, // Using persisted reducer
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disabling serializable check for Redux Toolkit
    })
      .concat(api.session.middleware) // Adding session middleware
      .concat(api.pos.middleware) // Adding POS middleware
      .concat(api.search.middleware) // Adding search middleware
      .concat(api.agency.middleware) // Adding agency middleware
      .concat(api.environment.middleware) // Adding environment middleware
      .concat(api.payment.middleware) // Adding payment middleware
      .concat(api.reservation.middleware) // Adding reservation middleware
      .concat(api.locales.middleware) // Adding locales middleware
      .concat(sessionMiddleware), // Adding custom session middleware
});

// Creating a persistor for the Redux store
export const persistor = persistStore(store);

// Type definition for the Redux store dispatch function
export type AppDispatch = typeof store.dispatch;

// Type definition for the RootState of the Redux store
export type RootState = ReturnType<typeof combinedReducer>;

// Custom hook for accessing the useDispatch hook with typed dispatch
export const useTypedDispatch = () => useDispatch<AppDispatch>();

// Custom hook for accessing the useSelector hook with typed state
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
