import classNames from "classnames"; // Import classNames library for conditionally joining class names.

// BASE
import { ReactComponent as Logo } from "../../../assets/icons/logo.svg"; // Import Logo SVG component.
import { ReactComponent as Plus } from "../../../assets/icons/plus.svg"; // Import Plus SVG component.
import { ReactComponent as Minus } from "../../../assets/icons/minus.svg"; // Import Minus SVG component.
import { ReactComponent as Checked } from "../../../assets/icons/checked.svg"; // Import Checked SVG component.
import { ReactComponent as Unchecked } from "../../../assets/icons/unchecked.svg"; // Import Unchecked SVG component.
import { ReactComponent as Loupe } from "../../../assets/icons/loupe.svg"; // Import Loupe SVG component.

import styles from "./index.module.scss"; // Import styles for SvgIcon component.

// Define a base collection of SVG icons.
const BASE: Record<string, React.FC<React.SVGProps<SVGSVGElement>>> = {
  logo: Logo, // Logo SVG icon component
  plus: Plus, // Plus SVG icon component
  minus: Minus, // Minus SVG icon component
  checked: Checked, // Checked SVG icon component
  unchecked: Unchecked, // Unchecked SVG icon component
  loupe: Loupe, // Loupe SVG icon component
};

// Combine the base SVG icons with additional types if needed.
const SVG_BY_TYPE = {
  ...BASE,
};

interface ISvgIconProps {
  type: keyof typeof SVG_BY_TYPE;
  className?: string;
  onClick?: () => void;
}

/**
 * SvgIcon component renders an SVG icon based on the provided type.
 *
 * @param {string} type - The type of SVG icon to render.
 * @param {string} [className=""] - Additional class name(s) for styling purposes.
 * @param {function} [onClick=()=>{}] - Function to execute on icon click event.
 * @returns {JSX.Element} - The rendered SvgIcon JSX element.
 */
function SvgIcon({ type, className, onClick }: ISvgIconProps) {
  // Retrieve the SVG component based on the provided type.
  const CustomSvg = SVG_BY_TYPE[type] || "svg";

  // Render the SVG icon.
  return (
    <CustomSvg
      className={classNames(styles.icon, className)} // Conditionally join class names for the SVG icon.
      onClick={onClick} // Attach click event handler if provided.
    />
  );
}

// Set default props for SvgIcon component.
SvgIcon.defaultProps = {
  className: "", // Default className prop is an empty string.
  onClick: () => {}, // Default onClick prop is an empty function.
};

// Export SvgIcon component as default.
export default SvgIcon;
