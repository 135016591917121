import { RefObject, useEffect } from "react";

/**
 * Custom hook that triggers a callback when a click occurs outside a specified DOM element.
 * @param {RefObject<Node>} ref - Reference to the DOM element to monitor for clicks outside.
 * @param {(e: MouseEvent) => void} handler - Callback function to be executed when a click occurs outside the element.
 */
const useOnClickOutside = (
  ref: RefObject<Node>,
  handler: (e: MouseEvent) => void,
) => {
  useEffect(() => {
    // Check if the clicked element is outside the specified DOM element
    const listener = (e: MouseEvent) => {
      if (!ref.current || ref.current.contains(e.target as Node)) {
        return;
      }

      // Call the handler function when a click occurs outside the element
      handler(e);
    };

    // Add event listener to the document for mousedown events
    document.addEventListener("mousedown", listener);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
};

// Export useOnClickOutside outside
export default useOnClickOutside;
