// Importing necessary dependencies from React and other libraries for component functionality.
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next"; // Hook for translation.
import dayjs from "dayjs"; // Library for date manipulation.
import classNames from "classnames"; // Library for conditionally joining classNames.
import { nanoid } from "@reduxjs/toolkit"; // Function for generating unique IDs.

// Importing custom hook for accessing typed Redux store state.
import { useTypedSelector } from "../../../store/store";

// Importing utility function for formatting currency.
import { currencyToFormat } from "../../../utils/helpers/currency";

// Importing shared Collapsible component for collapsible sections.
import Collapsible from "../../shared/Collapsible";

// Importing component styles.
import styles from "./index.module.scss";

// Component for displaying booking details.
function BookingDetails() {
  const { t } = useTranslation(); // Initializing i18n translation hook.

  // Retrieving necessary data from Redux store using custom hook useTypedSelector.
  const { cruise } = useTypedSelector((state) => state.search);
  const { date_format } = useTypedSelector((state) => state.environment);
  const { rooms } = useTypedSelector((state) => state.rooms);
  const { payment_schedule } = useTypedSelector((state) => state.pricing);

  const [expanded, setExpanded] = useState(true); // State for controlling collapsible section expansion.

  // Calculating total price for all rooms using useMemo to optimize performance.
  const totalPrice = useMemo(() => {
    const totalSum = Object.values(rooms ?? {}).reduce(
      (accumulator, currentItem) => {
        const totalPriceAsNumber = parseFloat(
          (currentItem.pricing?.total_price ?? "").replace(",", ""),
        );

        const nccf =
          currentItem.pricing?.guests?.reduce((accNccf, currentNccf) => {
            const nccfAsNumber = parseFloat(
              (currentNccf?.nccf ?? "").replace(",", ""),
            );

            return accNccf + nccfAsNumber;
          }, 0) ?? 0;

        return accumulator + totalPriceAsNumber + nccf;
      },
      0,
    );

    return currencyToFormat(totalSum, cruise?.pricing?.[0]?.currency ?? "USD");
  }, [rooms]);

  return (
    <Collapsible
      expanded={expanded}
      onExpand={setExpanded}
      renderHeader={() => (
        // Header section of the collapsible containing booking details title.
        <p className={styles.header}>{t("booking details")}</p>
      )}>
      {/* Body section of the collapsible containing actual booking details. */}
      <div className={styles.body}>
        {/* Displaying cruise name */}
        <p className={styles.name}>{cruise?.cruise.name}</p>

        {/* Table for displaying embark and debark dates */}
        <table>
          <tbody>
            <tr>
              <td>
                <p className={styles.tableHeading}>{t("embarks")}</p>
              </td>

              <td>
                <p className={styles.tableValue}>
                  {dayjs(cruise?.embark ?? "").format(date_format)}
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <p className={styles.tableHeading}>{t("debarks")}</p>
              </td>

              <td>
                <p className={styles.tableValue}>
                  {dayjs(cruise?.embark)
                    .add(cruise?.cruise.nights ?? 1, "days")
                    .format(date_format)}
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        {/* Horizontal line */}
        <hr className={styles.line} />

        {/* Looping over rooms and displaying details for each */}
        {Object.entries(rooms ?? {}).map(([roomKey, room]) => {
          const { grade, guestsNumber, pricing } = room;

          return (
            <div key={nanoid()} className={styles.room}>
              <p
                className={
                  styles.roomNumber
                }>{`${t("stateroom")} ${roomKey}`}</p>

              <p className={styles.roomName}>{grade?.name ?? ""}</p>

              <table>
                <tbody>
                  <tr>
                    <td>
                      <p className={styles.tableHeading}>{t("guests")}</p>
                    </td>

                    <td>
                      <p className={styles.tableValue}>
                        {`${guestsNumber} ${t("adult(s)")}`}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <hr className={styles.line} />

              {/* Looping over guests within the room and displaying their details */}
              <div className={styles.guests}>
                {(pricing?.guests ?? []).map((guest) => {
                  const {
                    guest: guestNumber,
                    total_price,
                    gft,
                    total_fare,
                  } = guest;

                  return (
                    <div key={guestNumber} className={styles.guest}>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <p
                                className={classNames(
                                  styles.tableHeading,
                                  styles.guestHeading,
                                )}>
                                {t("fare")}
                              </p>
                            </td>

                            <td>
                              <p
                                className={classNames(
                                  styles.tableValue,
                                  styles.guestValue,
                                )}>
                                {currencyToFormat(
                                  total_fare ?? 0,
                                  cruise?.pricing?.[0]?.currency ?? "USD",
                                )}
                              </p>
                            </td>
                          </tr>

                          {!!+gft && (
                            <tr>
                              <td>
                                <p
                                  className={classNames(
                                    styles.tableHeading,
                                    styles.guestHeading,
                                  )}>
                                  {t("tax")}
                                </p>
                              </td>

                              <td>
                                <p
                                  className={classNames(
                                    styles.tableValue,
                                    styles.guestValue,
                                  )}>
                                  {currencyToFormat(
                                    gft,
                                    cruise?.pricing?.[0]?.currency ?? "USD",
                                  )}
                                </p>
                              </td>
                            </tr>
                          )}

                          <tr>
                            <td>
                              <p
                                className={
                                  styles.tableHeading
                                }>{`${t("guest")} ${guestNumber} ${t("subtotal")}`}</p>
                            </td>

                            <td>
                              <p className={styles.tableValue}>
                                {currencyToFormat(
                                  total_price,
                                  cruise?.pricing?.[0]?.currency ?? "USD",
                                )}
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      {/* Horizontal line */}
                      <hr className={styles.line} />
                    </div>
                  );
                })}
              </div>

              <table>
                <tbody>
                  <tr>
                    <td>
                      <p
                        className={
                          styles.tableHeading
                        }>{`${t("stateroom")} ${roomKey} ${t("fare")}:`}</p>
                    </td>

                    <td>
                      <p className={styles.tableValue}>
                        {currencyToFormat(
                          room.pricing?.total_price ?? "0",
                          cruise?.pricing?.[0]?.currency ?? "USD",
                        )}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <hr className={styles.line} />
            </div>
          );
        })}

        <table>
          <tbody>
            <tr>
              <td>
                <p
                  className={classNames(
                    styles.tableHeading,
                    styles.totalPriceHeading,
                  )}>
                  {t("total fare:")}
                </p>
              </td>

              <td>
                <p
                  className={classNames(
                    styles.tableValue,
                    styles.totalPriceValue,
                  )}>
                  {totalPrice}
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        {/* Horizontal line */}
        <hr className={styles.line} />

        {/* Section for displaying payment schedule */}
        <div>
          <p className={styles.roomNumber}>{t("payment schedule")}</p>

          <p
            className={classNames(
              styles.tableValue,
              styles.scheduleTableValue,
            )}>
            {t("deposit & payment option")}
          </p>
        </div>

        {/* Table for displaying payment schedule details */}
        <table>
          <tbody>
            {/* Mapping over payment schedule entries and displaying each */}
            {Object.entries(payment_schedule).map(
              ([scheduleKey, scheduleItem]) => {
                const { date, amount } = scheduleItem;
                const isToday = dayjs(date).isSame(dayjs(), "day");

                // Determining title based on whether the payment date is today or not.
                const title = isToday
                  ? t("today")
                  : dayjs(date).format(date_format);

                return (
                  <tr key={scheduleKey}>
                    <td>
                      <p className={styles.tableHeading}>{title}</p>
                    </td>
                    <td>
                      <p className={styles.tableValue}>
                        {currencyToFormat(
                          amount,
                          cruise?.pricing?.[0]?.currency ?? "USD",
                        )}
                      </p>
                    </td>
                  </tr>
                );
              },
            )}
          </tbody>
        </table>
      </div>
    </Collapsible>
  );
}

export default BookingDetails;
