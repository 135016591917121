/**
 * Provides functionality related to localization using i18next.
 */
import { EventEmitter } from "events";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslations from "./locales/en/translation.json";
import esTranslations from "./locales/es/translation.json";

import { LocalesAPI } from "../../store/services/LocalesService";
import { store } from "../../store/store";

/**
 * Event emitter for handling locale changes.
 */
const localesEventEmitter = new EventEmitter();

/**
 * Transforms the fetched locale data into the required format.
 * @param {Object} inputData - The input locale data.
 * @returns {Object} The transformed locale data.
 */
function transformData(inputData) {
  const result = {};

  inputData.languages.forEach((item) => {
    const translation = {};

    item.translations.forEach((translate) => {
      translation[translate.original] = translate.translation;
    });
    result[item.language] = translation;
  });

  return result;
}

/**
 * Supported languages.
 */
const LANGUAGES = {
  EN: "en",
  ES: "es",
};

/**
 * Language detection configuration.
 */
const detection = {
  order: ["querystring", "navigator"],
  lookupQuerystring: "lng",
};

/**
 * Detects the language based on local storage or defaults to English.
 * @returns {string} The detected language.
 */
export const detectLanguage = () => {
  const localLanguage = localStorage.getItem("APP_LANG") ?? LANGUAGES.EN;

  return localLanguage;
};

/**
 * Resources containing translations for each language.
 */
const resources = {
  [LANGUAGES.EN]: {
    translation: enTranslations,
  },
  [LANGUAGES.ES]: {
    translation: esTranslations,
  },
};

/**
 * Fetches data from the locales service and sets the language accordingly.
 */
const fetchDataAndSetLanguage = async () => {
  try {
    const { data } = await store.dispatch(
      LocalesAPI.endpoints.initLocales.initiate(),
    );

    if (data?.languages) {
      localesEventEmitter.emit("localesChanged");
    }
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : String(error);

    throw new Error(errorMessage);
  }
};

fetchDataAndSetLanguage();

/**
 * Listens for locale changes and updates the resources accordingly.
 */
localesEventEmitter.on("localesChanged", () => {
  const { locales } = store.getState();
  const translations = transformData(locales);

  Object.entries(translations).forEach((value) => {
    resources[value[0]] = { translation: value[1] };
  });
});

/**
 * Language detector configuration.
 */
const languageDetector = {
  type: "languageDetector",
  detect: () => {
    const lng = detectLanguage();

    return lng;
  },
  init: () => {},
  cacheUserLanguage: () => {},
};

/**
 * Initializes i18next with the configured settings.
 */
i18n
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    detection,
    resources,
    fallbackLng: LANGUAGES.EN,
    supportedLngs: Object.values(LANGUAGES),
    interpolation: {
      escapeValue: false,
    },
    debug: false,
  });

export default i18n;
