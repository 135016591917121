import classNames from "classnames"; // Importing classNames library for dynamic class names

import LoadingIndicator from "../LoadingIndicator"; // Importing LoadingIndicator component
import SvgIcon from "../SvgIcon"; // Importing SvgIcon component

import styles from "./index.module.scss"; // Importing styles from SCSS module

interface IButtonProps {
  id?: string; // Optional ID attribute for the button
  label: string; // Required label for the button
  type?: "button" | "submit" | "reset"; // Optional type attribute for the button
  icon?: string; // Optional icon name for the button
  variant?: "primary" | "secondary" | "dark"; // Optional variant style for the button
  onClick?: () => void | Promise<void>; // Optional click event handler for the button
  loading?: boolean; // Optional flag indicating loading state
  disabled?: boolean; // Optional flag indicating disabled state
  className?: string; // Optional additional class name for the button
}

/**
 * Button component.
 *
 * @param {string} id - Optional ID attribute for the button.
 * @param {string} label - Required label for the button.
 * @param {"button"|"submit"|"reset"} type - Optional type attribute for the button.
 * @param {string} icon - Optional icon name for the button.
 * @param {"primary"|"secondary"|"dark"} variant - Optional variant style for the button.
 * @param {function} onClick - Optional click event handler for the button.
 * @param {boolean} loading - Optional flag indicating loading state.
 * @param {boolean} disabled - Optional flag indicating disabled state.
 * @param {string} className - Optional additional class name for the button.
 * @returns {JSX.Element} Button element.
 */
function Button({
  id,
  label,
  type = "button",
  icon,
  variant,
  onClick,
  loading,
  disabled,
  className,
}: IButtonProps) {
  return (
    <button
      id={id}
      className={classNames(
        styles.button,
        styles[`button_${variant ?? "primary"}`],
        className,
        { [styles.button_withIcon]: icon },
      )}
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={loading || disabled}
      onClick={onClick}>
      {loading ? <LoadingIndicator width={30} height={30} /> : label}

      {icon && <SvgIcon type={icon} />}
    </button>
  );
}

// Default props for the Button component
Button.defaultProps = {
  id: "",
  type: "button",
  icon: "",
  variant: "primary",
  onClick: () => {},
  loading: false,
  disabled: false,
  className: "",
};

// Exporting the Button component
export default Button;
