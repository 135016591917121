import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react"; // Import necessary hooks and components from React
import { nanoid } from "@reduxjs/toolkit"; // Import the nanoid function from Redux Toolkit to generate unique IDs
import classNames from "classnames"; // Import classNames library for conditional CSS class assignment

import useOnClickOutside from "../../../hooks/useClickOutside"; // Import custom hook for handling click outside event

import LoadingIndicator from "../LoadingIndicator"; // Import LoadingIndicator component
import SvgIcon from "../SvgIcon"; // Import SvgIcon component

import styles from "./index.module.scss"; // Import component styles

// Define props interface for the SearchInput component
interface ISearchInputProps {
  className?: string; // Custom CSS class name for the component
  value: string; // Value of the input field
  valueKey: string; // Key to identify the value in the data object
  displayKey: string; // Key to identify the display text in the data object
  results: Array<Record<string, string>> | null | []; // Array of search results
  label?: string; // Label for the input field
  placeholder?: string; // Placeholder text for the input field
  errorMessage?: string; // Error message to display
  isLoading?: boolean; // Boolean indicating whether data is loading
  isRequired?: boolean; // Boolean indicating whether the input is required
  readOnly?: boolean; // Boolean indicating whether the input is read-only
  isMultiple: boolean; // Boolean indicating whether multiple selections are allowed
  showError?: boolean; // Boolean indicating whether to show error message
  onClick?: () => void; // Function to handle click event
  changes?: Array<Record<string, string>>; // Array of changes in the selected items
  onChosenChange?: ({
    items,
    value,
    valueKey,
  }: {
    items?: Array<Record<string, string>>;
    value: Record<string, string>;
    valueKey: string;
  }) => void; // Function to handle changes in chosen items
  onChange?: ({
    value,
    valueKey,
  }: {
    value: string;
    valueKey?: string;
  }) => void; // Function to handle input change event
}

/**
 * SearchInput component for displaying an input field with search functionality.
 *
 * @param {string} className - Additional CSS class names for styling.
 * @param {string} value - The current value of the input field.
 * @param {string} valueKey - The key to access the value property of the items in the results array.
 * @param {string} displayKey - The key to access the display property of the items in the results array.
 * @param {Array<Record<string, string>> | null | []} results - An array of search results.
 * @param {string} label - The label for the input field.
 * @param {string} placeholder - The placeholder text for the input field.
 * @param {string} errorMessage - The error message to display if there is an error.
 * @param {boolean} isLoading - A flag indicating whether data is currently loading.
 * @param {boolean} isRequired - A flag indicating whether the input field is required.
 * @param {boolean} readOnly - A flag indicating whether the input field is read-only.
 * @param {boolean} isMultiple - A flag indicating whether multiple selections are allowed.
 * @param {boolean} showError - A flag indicating whether to display the error message.
 * @param {function} onClick - Event handler for click events on the input field.
 * @param {function} onChosenChange - Event handler for changes in chosen items.
 * @param {function} onChange - Event handler for changes in the input field's value.
 * @param {Array<Record<string, string>>} changes - An array of changes in chosen items.
 * @returns {JSX.Element} The JSX element representing the SearchInput component.
 */
function SearchInput({
  className,
  value,
  valueKey,
  displayKey,
  results,
  label,
  placeholder,
  errorMessage,
  isLoading,
  isRequired,
  readOnly,
  isMultiple,
  showError,
  onClick,
  onChosenChange,
  onChange,
  changes,
}: ISearchInputProps) {
  // State for storing data
  const [internalValue, setInternalValue] = useState(value);
  const [internalResults, setInternalResults] = useState(results);
  const [isResultsVisible, setIsResultsVisible] = useState(false);
  const [chosenItems, setChosenItems] = useState<Array<Record<string, string>>>(
    [],
  );

  // Generate a unique ID for the input field
  const id = useMemo(nanoid, []);

  // Ref for handling click outside event
  const outsideRef = useRef(null);

  // Ref for storing timeout ID
  const timeoutIdRef: MutableRefObject<ReturnType<typeof setTimeout> | null> =
    useRef(null);

  // Function to handle input change event
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(timeoutIdRef.current as ReturnType<typeof setTimeout>);
    setInternalValue(e.target.value);

    timeoutIdRef.current = setTimeout(() => {
      if (onChange) {
        onChange({ value: e.target.value, valueKey });
      }
    }, 500);
  };

  // Function to handle selection of an item
  const handleChose = (item: Record<string, string>) => () => {
    if (isMultiple) {
      const newChosenItems = [...chosenItems, item];

      setChosenItems(newChosenItems);

      if (onChosenChange) {
        onChosenChange({ items: newChosenItems, value: item, valueKey });
      }
    } else if (onChosenChange) {
      onChosenChange({ value: item, valueKey });
      setIsResultsVisible(false);
    }
  };

  // Function to handle click event
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  // Function to handle input focus event
  const handleFocus = () => {
    setIsResultsVisible(true);
  };

  // Function to handle click outside event
  const handleClickOutside = () => {
    if (isLoading) {
      return;
    }

    setIsResultsVisible(false);
  };

  // Function to handle removal of a chosen item
  const handleRemoveChosen = (item: Record<string, string>) => () => {
    const newChosen = chosenItems.filter(
      (el) => el[displayKey] !== item[displayKey],
    );

    setChosenItems(newChosen);

    if (onChosenChange) {
      onChosenChange({ items: newChosen, value: item, valueKey });
    }
  };

  // Effect to initialize chosen items based on changes
  useEffect(() => {
    setChosenItems(changes ?? []);
  }, [changes]);

  // Effect to update internal value based on external value
  useEffect(() => {
    setInternalValue(value);
  }, [value, chosenItems]);

  // Effect to update results visibility based on loading state and results
  useEffect(() => {
    if (isLoading) {
      setIsResultsVisible(true);
    }
  }, [isLoading, results]);

  // Effect to filter out chosen items from search results
  useEffect(() => {
    const chosenDisplayKeys = chosenItems.map((el) => el[displayKey]);

    const filteredResults = (results ?? []).filter(
      (el) => !chosenDisplayKeys.includes(el[displayKey]),
    );

    setInternalResults(filteredResults);
  }, [results, chosenItems]);

  // Custom hook to handle click outside event
  useOnClickOutside(outsideRef, handleClickOutside);

  return (
    <div className={classNames(styles.container, className)}>
      {/* Render label if provided */}
      {label && (
        <label htmlFor={id} className={styles.label}>
          {label}
          {isRequired && <span className={styles.asterisk}>*</span>}
        </label>
      )}

      {/* Render input field, chosen items, and search results */}
      <div className={styles.fieldContainer}>
        <input
          id={id}
          className={classNames(styles.field, {
            [styles.field_error]: !!errorMessage,
          })}
          value={internalValue}
          placeholder={placeholder}
          readOnly={readOnly}
          onClick={handleClick}
          onChange={handleChange}
          onFocus={handleFocus}
        />

        <div
          className={classNames(styles.chosen, {
            [styles.chosen_visible]: chosenItems.length,
          })}>
          {/* Render chosen items */}
          {chosenItems.map((el) => {
            return (
              <div key={nanoid()} className={styles.chosenItem}>
                <p>{el[displayKey]}</p>
                <SvgIcon
                  type="plus"
                  className={styles.closeIcon}
                  onClick={handleRemoveChosen(el)}
                />
              </div>
            );
          })}
        </div>

        <div
          ref={outsideRef}
          className={classNames(styles.results, {
            [styles.results_visible]: isResultsVisible,
          })}>
          {/* Render loading indicator if data is loading */}
          {isLoading && (
            <LoadingIndicator
              className={styles.loader}
              width={24}
              height={24}
            />
          )}

          {!isLoading &&
            internalResults &&
            internalResults?.length > 0 &&
            internalResults.map((el) => (
              <div
                key={nanoid()}
                className={styles.resultsItem}
                role="option"
                aria-selected
                tabIndex={0}
                onClick={handleChose(el)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") handleChose(el)();
                }}>
                <p>{el[displayKey]}</p>
              </div>
            ))}

          {!isLoading && internalResults?.length === 0 && (
            <p className={styles.noResults}>No results found</p>
          )}
        </div>
      </div>

      {showError ? <span className={styles.error}>{errorMessage}</span> : null}
    </div>
  );
}

// Default props for the SearchInput component
SearchInput.defaultProps = {
  isLoading: false,
  className: "",
  label: "",
  placeholder: "",
  errorMessage: "",
  isRequired: false,
  readOnly: "",
  showError: true,
  changes: [],
  onClick: () => {},
  onChange: () => {},
  onChosenChange: () => {},
};

// Export the RangeSlider component as the default export
export default SearchInput;
