import { useEffect, useState } from "react";

import SvgIcon from "../SvgIcon";

import styles from "./index.module.scss";

// Defining props interface for the Checkbox component
interface ICheckboxProps {
  label?: string | React.ReactNode; // Label to display next to the checkbox
  value: boolean; // Value indicating whether the checkbox is checked or not
  valueKey?: string; // Optional key to identify the value (useful for form handling)
  onChange?: ({
    value,
    valueKey,
  }: {
    value: boolean;
    valueKey: string;
  }) => void; // Function to handle checkbox value changes
}

/**
 * Checkbox component.
 *
 * @param {string | React.ReactNode} [label] - The label displayed next to the checkbox.
 * @param {boolean} value - The current value of the checkbox.
 * @param {string} [valueKey] - The key associated with the value.
 * @param {function} [onChange] - Function called when the checkbox value changes.
 * @param {boolean} onChange.value - The new value of the checkbox.
 * @param {string} onChange.valueKey - The key associated with the value.
 * @returns {JSX.Element} Checkbox component JSX.
 */
function Checkbox({ label, value, valueKey, onChange }: ICheckboxProps) {
  const [internalValue, setInternalValue] = useState(!!value); // State to manage the internal value of the checkbox

  // Function to handle checkbox value changes
  const handleChange = (newValue: boolean) => () => {
    // If an onChange function is provided, call it with the new value and valueKey
    if (onChange) {
      onChange({ value: newValue, valueKey: valueKey ?? "" });
    }

    // If no onChange function is provided, update the internal state
    if (!onChange) {
      setInternalValue(value);
    }
  };

  // Effect to synchronize internal state with external value prop
  useEffect(() => {
    setInternalValue(!!value);
  }, [value]);

  return (
    <div
      className={styles.container}
      onClick={handleChange(!internalValue)}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          handleChange(!internalValue)();
        }
      }}
      role="checkbox"
      aria-checked={internalValue}
      tabIndex={0}>
      <SvgIcon
        type={internalValue ? "checked" : "unchecked"}
        className={styles.icon}
      />

      {label && <p className={styles.label}>{label}</p>}
    </div>
  );
}

Checkbox.defaultProps = {
  label: "",
  valueKey: undefined,
  onChange: () => {},
};

// Exporting the Checkbox component as default
export default Checkbox;
