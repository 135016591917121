import i18n from "../i18n";

// CONSTANTS
import {
  IRouteElement,
  ROOT,
  SEARCH_RESULTS,
  CRUISE,
  ROOMS,
  CABINS_SELECT,
  PASSENGERS,
  PAYMENT,
  PAYMENT_CONFIRMATION,
} from "./routes";

// PAGES
import Search from "../../components/pages/Search";
import SearchResults from "../../components/pages/SearchResults";
import Cruise from "../../components/pages/Cruise";
import Rooms from "../../components/pages/Rooms";
import CabinSelect from "../../components/pages/CabinSelect";
import Passengers from "../../components/pages/Passengers";
import Payment from "../../components/pages/Payment";
import PaymentConfirmation from "../../components/pages/PaymentConfirmation";

const PRIVATE_ROUTES: IRouteElement = {
  [ROOT]: {
    element: Search,
    layout: "auth",
    title: i18n.t("search"),
  },
  [SEARCH_RESULTS]: {
    element: SearchResults,
    layout: "search",
    title: i18n.t("search results"),
    crumbParams: ["start_date", "end_date"],
  },
  [CRUISE]: {
    element: Cruise,
    layout: "search",
    title: i18n.t("number of staterooms"),
    crumbParams: ["start_date", "end_date", "source"],
  },
  [ROOMS]: {
    element: Rooms,
    layout: "search",
    title: i18n.t("staterooms"),
    crumbParams: [
      "start_date",
      "end_date",
      "rooms",
      "room",
      "fare_1",
      "grade_1",
      "guests_1",
      "market_1",
      "source",
    ],
  },
  [CABINS_SELECT]: {
    element: CabinSelect,
    layout: "search",
    title: i18n.t("staterooms"),
    crumbParams: [
      "start_date",
      "end_date",
      "rooms",
      "room",
      "fare_*",
      "grade_*",
      "guests_*",
      "market_*",
      "cabin_*",
      "deck_*",
      "source",
    ],
  },
  [PASSENGERS]: {
    element: Passengers,
    layout: "search",
    title: i18n.t("guests"),
    crumbParams: [
      "start_date",
      "end_date",
      "rooms",
      "room",
      "fare_*",
      "grade_*",
      "guests_*",
      "market_*",
      "cabin_*",
      "deck_*",
      "source",
    ],
  },
  [PAYMENT]: {
    element: Payment,
    layout: "search",
    title: i18n.t("payment"),
  },
  [PAYMENT_CONFIRMATION]: {
    element: PaymentConfirmation,
    layout: "search",
    title: i18n.t("payment_confirmation"),
  },
};

export default PRIVATE_ROUTES;
